import React, {useState, useEffect} from "react";
import {GQLService} from "../services/GQLService";
import {Oval} from "react-loader-spinner";

export interface LoadingComponentProps {


}

export interface LoadingComponentState {

}

function LoadingComponent(props: LoadingComponentProps) {
    const [state, setState] = useState<LoadingComponentState>({

    });

    return (
        <div>
            <Oval
                height={100}
                width={100}
                wrapperStyle={{}}
                wrapperClass="justify-content-center oval"
                visible={true}
                ariaLabel='oval-loading'
                strokeWidth={4}
                strokeWidthSecondary={4}
            />

        </div>
    );
}
export default LoadingComponent;


