import React, { ChangeEvent, useEffect, useState } from "react";
import { GQLService } from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ImageEdit, { ImageEditComponentState } from "../components/ImageEdit";
import {useNavigate, useParams} from "react-router";
import {Link, useLocation} from "react-router-dom";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import InferenceRequestListComponent from "../components/InferenceRequestListComponent";
import {GlobalModalService} from "../components/GlobalModalComponent";

export interface UserProfileComponentProps {
  // wizardComponent: HomeWizard;
}
export interface UserProfileState {
  loaded?: boolean;
}


const UserProfile = (props: UserProfileComponentProps) => {
  const [state, setState] = useState<UserProfileState>({

  });


/*  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };*/
  const params = useParams();
  const navigate = useNavigate();
  useEffect( () => {
    if (state.loaded) {
      return;
    }
    GlobalModalService.initTimeout({
      action: () => {
        if (!GQLService.isLoggedIn()) {
          GlobalModalService.displayDiscountCodePrompt();
          return;
        }
        if (
            GQLService.isLoggedIn() &&
            GQLService.user().username === params.username &&
            !GQLService.hasSubscription()
        ){
            GlobalModalService.displayAfterInferenceModal();
        }

      }
    })
    if (!GQLService.isLoggedIn()) {
      setState({
        ...state,
        loaded: true
      });
      return;
    }
    let query: any = {};
    if (params.username) {
      query.username = params.username;
    }
    GQLService.listInferenceRequest(query);
    return () => {
      GlobalModalService.clearTimeouts();
    }
  });

  return (
   <div className='container'>
     {
       GQLService.hasRole('admin') &&
       <>
       <div className="row">
         <div className="col-12 offset-lg-12 col-lg-12 offset-xl-12 col-xl-12">
           <BreadcrumbComponent />
         </div>
       </div>
       <div className="row">
          <div className="col-12 offset-lg-12 col-lg-12 offset-xl-12 col-xl-124">
           <ul className="nav nav-tabs">
             <li className="nav-item">
               <Link
                 className={
                   "nav-link"
                 }
                 to={`/${params.username}/models`}
               >
                 Models
               </Link>
             </li>
             <li className="nav-item">
               <Link
                   className={
                     "nav-link"
                   }
                   to={`/${params.username}/instances`}
               >
                 Instances
               </Link>
             </li>
             <li className="nav-item">
               <Link
                   className={
                     "nav-link"
                   }
                   to={`/${params.username}/reqs`}
               >
                 Requests
               </Link>
             </li>
            {/* <li className="nav-item">
               <div className="dropdown">
                 <button className="btn btn-secondary dropdown-toggle" type="button"
                         id={"userOptions"}
                         data-bs-toggle="dropdown" aria-expanded="false">
                    Admin
                 </button>
                 <ul className="dropdown-menu dropdown-menu-dark"
                     aria-labelledby={"userOptions"}>
                    <li>
                       <a className="dropdown-item" href="#"
                          onClick={(event) => deleteUser()}>
                         Delete
                       </a>
                     </li>

                 </ul>
               </div>
             </li>*/}
           </ul>

         </div>
       </div>
       </>
     }
     <InferenceRequestListComponent query={{ username: params.username }} />
   </div>
  );
};
export default UserProfile;
