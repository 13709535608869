import { Outlet } from "react-router";
import React, { useState } from "react";
import { GQLService } from "../services/GQLService";
/*import { LiveChatWidget } from "@livechat/widget-react"*/
import {Link, useLocation} from "react-router-dom";
import GlobalModalComponent, {GlobalModalService} from "../components/GlobalModalComponent";
import {Alert, Button, Toast, ToastContainer} from "react-bootstrap";


export interface LayoutComponentProps {

}

export interface LayoutComponentState {
  navClassNameExtra?: string;
  isLoggedIn: boolean

  toasts?: LayoutToast[]
}
export interface LayoutToast{
  id?: string;
  bg?: string;
  header?: any;
  body?: any;
}

function Layout(props: LayoutComponentProps) {
  const [state, setState] = useState<LayoutComponentState>({
    isLoggedIn: GQLService.isLoggedIn()
  });
  GQLService.setLayoutStateFunction(state, setState);
  async function logout(event: React.MouseEvent<HTMLAnchorElement>) {
    await GQLService.logout();
    document.location.href = '/login';
  }
  let location = useLocation();

  React.useEffect(() => {
    // GlobalModalService.clearTimeouts();
  }, [location]);
  return (
    <main
      className={`flex min-h-screen flex-col items-center justify-between p-24`}
    >
      <ToastContainer className='p-5 position-fixed'>
        {
          state.toasts &&
          state.toasts.map((toast) => {
            return  <Toast animation={true} key={toast.id} bg={toast.bg || "danger" } onClose={() => { GQLService.hideToast(toast); }}>
              <Toast.Header>
                <strong className="mr-auto">
                  {toast.header || "Error" }
                </strong>
              </Toast.Header>
              <Toast.Body>
                {toast.body}
              </Toast.Body>
            </Toast>
          })
        }

      </ToastContainer>
      <div className="container-fluid px-0">
        <nav className={"navbar navbar-expand-lg " + (state.navClassNameExtra || "")}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img
                src={process.env.PUBLIC_URL + '/assets/img/drawnby-logo.png'}
                className="main-logo"
                alt="drawnByAi Logo"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="navbar-collapse collapse d-xl-flex  justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-2 mb-lg-0 me-0 text-right flex-row-reverse">
                {/*<li className="nav-item d-none">
                  <Link className="nav-link" aria-current="page" to="#">
                    Pricing
                  </Link >
                </li>
                <li className="nav-item d-none">
                  <Link className="nav-link" to="#">
                    About Us
                  </Link>
                </li>
                {
                  !state.isLoggedIn &&
                  <li className="nav-item d-none">
                    <Link className="nav-link" to='/'>Sign Up</Link>
                  </li>
                }*/}
                {
                  !state.isLoggedIn &&
                  <li className="nav-itemt">
                    <Link className="nav-link" to='/login'>Login</Link>
                  </li>
                }
                {
                  state.isLoggedIn &&
                  <li className="nav-item">
                    <Link className="nav-link" to='/' onClick={(event) => logout(event)}>
                      Logout
                    </Link>
                  </li>
                }
              </ul>
            </div>
          </div>
        </nav>
        <Outlet />
        <div className="container-fluid light-grey-bg py-4">
          <div className="row justify-content-center">
            <div className="col-auto">
              <a className="btn btn-link" href={process.env.PUBLIC_URL + '/DataPolicy.html'}>Data Policy</a>
            </div>
            <div className="col-auto">
              <a className="btn btn-link" href={process.env.PUBLIC_URL + '/PrivacyPolicy.html'}>Privacy Policy</a>
            </div>
           {/* <div className="col-auto">
              <a className="btn btn-link" href="/affiliates">Affiliates</a>
            </div>*/}
            <div className="col-auto">
                <a className="btn btn-link" href="mailto:hello@drawnby.ai">hello@drawnby.ai</a>
            </div>

            <div className="col-auto"></div>
          </div>
          <div className="row mt-4">
            <div className="col text-center">Powered By</div>
          </div>
          <div className="row">

            <div className="col text-center">
              <a href="https://schematical.com?utm_source=drawnby_ai" target="_blank">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/schematical-logo.png'}
                  className="main-logo"
                  alt="Schematical Logo"
                />
              </a>
            </div>
          </div>

        </div>
{/*        <LiveChatWidget license="15489441" visibility="minimized" />*/}
        <GlobalModalComponent />
      </div>
    </main>
  )
}
export default Layout;