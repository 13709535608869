import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { GQLService } from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ImageEdit, { ImageEditComponentState } from "../components/ImageEdit";
import { useNavigate, useParams } from "react-router";
import BreadcrumbComponent from "../components/BreadcrumbComponent";

export interface TrainingModelJobDetailComponentProps {

}
export interface TrainingModelJobDetailState {
  loaded?: boolean;
  jsonError?: Error,
  settings?:string;
  trainingModelJob?: {
    _id?: string,
    settings?: any;
    trainingModelNamespace?: string;
    trainingModel?: {
      _id?: string,
      name?: string,
      namespace?: string,
      user?: {
        username: string
      },
      trainingModelInstances?: any[],
      jobs?: any[]
      inferenceRequests?: any[]
    }
  };

}


const TrainingModelJobDetail = (props: TrainingModelJobDetailComponentProps) => {
  const params = useParams();
  const [state, setState] = useState<TrainingModelJobDetailState>({

  });
  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let { name, value } = event.target;
    const parts = name.split('.');
    const entityName = parts[0];
    const entityProperty = parts[1];
    const newState: any = {
      ...state,
      trainingModelJob: {
        ...state.trainingModelJob
      }
    }
    if (
        entityName === 'trainingModelJob' &&
        entityProperty === 'settings'
    ) {
      newState.settings = value;
      try {

        value = JSON.parse(value);
        newState.jsonError = null;

      }catch (err) {
        newState.jsonError = err;
      }
    }
    newState[entityName][entityProperty] = value;
    setState(newState);
  };
  const save = async (event: React.MouseEvent<HTMLButtonElement>) =>{
    event.preventDefault();
    console.log(state);
    if (!state.trainingModelJob?._id){
      // Create
      const trainingModelJob = await GQLService.createTrainingModelJob(state.trainingModelJob);
      setState({
        ...state,
        trainingModelJob: {
          ...state.trainingModelJob,
          ...trainingModelJob
        }
      });
      return navigate('/' + trainingModelJob.model?.user?.username + '/models/' + trainingModelJob.model?._id + '/jobs/' + trainingModelJob?._id);
    } else {
      throw new Error("There is no updating on TrainingModelJobs. TODO: Add Cancel");
      // Update
      /*const trainingModel = await GQLService.updateTrainingModel({
        _id: state.trainingModelJob._id,
      });
      return navigate('/' + trainingModel?.user?.username + '/models/' + trainingModel?.namespace);*/
    }
  }
  useEffect( () => {
    if (state.loaded){
      return;
    }
    if (!params.model){
      throw new Error("Missing `model` in uri params");
    }
    if (!params.job){
      const settings = {
        baseModel:"Lykon/DreamShaper",
        steps:50,
        learningRate:"1e-7",
        extraRunArgs: [

        ],
      };
      setState({
        ...state,
        settings: JSON.stringify(settings, null, 3),
        trainingModelJob: {
          // trainingModelId: params.model
          settings,
          trainingModelNamespace: params.model
        },
        loaded: true
      });
      return;
    }

    GQLService.listTrainingModelJob({
      _id: params.job
    })
   .then((trainingModelJobs: any) => {
     const trainingModelJob = trainingModelJobs.find((tm: any) => tm._id === params.job);
     if (!trainingModelJob) {
       throw new Error("Could not find TrainingModelJob with _id: " + params.job)
     }
     setState({
       ...state,
       settings: JSON.stringify( trainingModelJob.settings, null, 3),
       trainingModelJob,
       loaded: true
     });
   });
  });
  return (
   <div className='container'>
     {state.trainingModelJob &&
       <div className='row'>
         <h1>{state.trainingModelJob?._id}</h1>
         <BreadcrumbComponent />
         <div className='container'>
           <div className='row'>
             <form className="row mt-5">


               <div className="col-12 mb-3 text-start">
                 <label className="form-label">Settings</label>
                 {
                   state.jsonError &&
                     <div className="alert alert-danger" role="alert">
                       {state.jsonError.message}
                     </div>
                 }
                 <textarea
                     className="form-control"
                     name="trainingModelJob.settings"
                     rows={10}
                     onChange={handleChange.bind(this)}
                     value={state.settings}
                     readOnly={!!state.trainingModelJob?._id}
                 ></textarea>{}

               </div>
               {
                 !state.trainingModelJob?._id &&
                   <div className="col-12 mb-3 text-start">
                     <button
                       className={`btn btn-primary w-100 d-inline-block`}
                       onClick={(event) => save(event)}
                     >
                       Save
                     </button>
                   </div>
               }

             </form>
           </div>
         </div>

      {/*   <div className='container'>
           <div className='row'>
             <h3>Instances</h3>
             <table className="table">
               <thead>
               <tr>
                 <th scope="col">Id</th>
                 <th scope="col">Name</th>
                 <th scope="col">User</th>
               </tr>
               </thead>
               <tbody>
               {state.trainingModel.trainingModelInstances?.map((trainingModelInstance) => {
                 return <tr key={trainingModelInstance._id}>
                   <th scope="row">
                     {trainingModelInstance.instance._id}
                   </th>
                   <td>
                     <a href={"/" + trainingModelInstance.instance.user.username + "/instances/" + trainingModelInstance.instance.namespace}>
                       {trainingModelInstance.instance.name}
                     </a>
                   </td>
                   <td>
                     <a href={"/" + trainingModelInstance.instance.user.username}>
                       {trainingModelInstance.instance.user.username}
                     </a>
                   </td>
                   <td>
                       <img src={trainingModelInstance.instance.files[0]?.downloadUrl} />
                   </td>
                 </tr>
               })}

               </tbody>
             </table>
           </div>
         </div>*/}


         {/*<div className='container'>
           <div className='row'>
             <h3>Jobs</h3>
             <table className="table">
               <thead>
               <tr>
                 <th scope="col">Id</th>
                 <th scope="col">Name</th>
                 <th scope="col">User</th>
               </tr>
               </thead>
               <tbody>
               {state.trainingModel.jobs?.map((job) => {
                 return <tr key={job._id}>
                   <th scope="row">
                     <a href={'https://us-east-1.console.aws.amazon.com/batch/home?region=us-east-1#jobs/detail/'+ job.awsBatchJobId} target="_blank">
                       {job.awsBatchJobId}
                     </a>
                   </th>
                   <td>
                     {job.status?.status}
                   </td>
                   <td>
                     {new Date(job.status?.createdAt).toString()}
                   </td>
                   <td>
                     {new Date(job.status?.startedAt).toString()}
                   </td>
                   <td>
                     {new Date(job.status?.stoppedAt).toString()}
                   </td>
                 </tr>
               })}

               </tbody>
             </table>
           </div>
         </div>



         <div className='container'>
           <div className='row'>
             <h3>Inference Requests</h3>
             <table className="table">
               <thead>
               <tr>
                 <th scope="col">Id</th>
                 <th scope="col">Name</th>
                 <th scope="col">User</th>
               </tr>
               </thead>
               <tbody>
               {state.trainingModel.inferenceRequests?.map((inferenceRequest) => {
                 return <tr key={inferenceRequest._id}>
                   <th scope="row">
                     <a href={'https://us-east-1.console.aws.amazon.com/batch/home?region=us-east-1#jobs/detail/'+ inferenceRequest.awsBatchJobId} target="_blank">
                       {inferenceRequest.awsBatchJobId}
                     </a>
                   </th>
                   <td>
                     {inferenceRequest.status?.status}
                   </td>
                   <td>
                     {new Date(inferenceRequest.status?.createdAt).toString()}
                   </td>
                   <td>
                     {new Date(inferenceRequest.status?.startedAt).toString()}
                   </td>
                   <td>
                     {new Date(inferenceRequest.status?.stoppedAt).toString()}
                   </td>
                 </tr>
               })}

               </tbody>
             </table>
           </div>
         </div>*/}



       </div>
     }
    </div>
  );
};
export default TrainingModelJobDetail;
