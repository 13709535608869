import React, { ChangeEvent, useEffect, useState } from "react";
import { GQLService } from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ImageEdit, { ImageEditComponentState } from "../components/ImageEdit";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export interface SignupCodeListComponentProps {
  // wizardComponent: HomeWizard;
}
export interface SignupCodeListState {
  notes: string;
  signupCodes: {
    _id: string,
    code: string,
    notes: string,
    user?: {
      username: string
    }
  }[],

}


const SignupCodeList = (props: SignupCodeListComponentProps) => {
  const [state, setState] = useState<SignupCodeListState>({
    notes: (new Date()).toDateString(),
    signupCodes: []
  });
  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };
  const create = async (event: React.MouseEvent<HTMLButtonElement>) =>{
    event.preventDefault();
    console.log(state);

    // Create
    const signupCode = await GQLService.createSignupCode({
      notes: state.notes
    });
    let signupCodes = state.signupCodes || [];
    signupCodes = [signupCode].concat(signupCodes);
    setState({
      ...state,
      signupCodes: signupCodes,
      notes: ""
    });

  }
  useEffect( () => {
    if(state.signupCodes.length !== 0){
      return;
    }
    GQLService.listSignupCode({ })
   .then((signupCodes) => {
     setState({
       ...state,
       signupCodes
     });
   });
  });
  return (
   <div className='container'>
     <div className='row'>
       <form className="row mt-5">
         <div className="col-12 mb-3 text-start">
           <label className="form-label">Notes</label>
           <input
               type="text"
               name="notes"
               className="form-control"
               value={state.notes}
               onChange={handleChange.bind(this)}
           />
         </div>

         <div className="col-12 mb-3 text-start">
           <button
               className={`btn btn-primary w-100 d-inline-block`}
               onClick={(event) => create(event)}
           >
             Create
           </button>
         </div>

       </form>
     </div>
      <div className='row'>
        <table className="table">
          <thead>
          <tr>
            <th scope="col">Code</th>
            <th scope="col">Notes</th>
            <th scope="col">User Id</th>
          </tr>
          </thead>
          <tbody>
          {state.signupCodes.map((signupCode) => {
            return <tr>
              <th scope="row">
                {
                  !signupCode.user &&
                    <Link to={'/?code=' +  signupCode.code}>
                      { signupCode.code}
                    </Link>
                }

              </th>
              <td>
                {signupCode.notes}
              </td>
              <td>
                {signupCode.user &&
                    <Link to={'/' + signupCode.user?.username}>
                      {signupCode.user?.username}
                    </Link>
                }
              </td>
            </tr>
          })}

          </tbody>
        </table>
      </div>
    </div>
  );
};
export default SignupCodeList;
