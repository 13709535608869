import {loadStripe} from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
    CardElement, CardNumberElement, CardExpiryElement, CardCvcElement,
} from '@stripe/react-stripe-js';
import React, {ChangeEvent, useEffect, useState} from "react";
import {LoadingButtonComponentState} from "@/components/LoadingButtonComponent";
import {StripeElementsOptionsMode} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {GQLService} from "../services/GQLService";
import {CreatePaymentMethodCardData} from "@stripe/stripe-js/types/stripe-js/payment-intents";
import {Button} from "react-bootstrap";
import LoadingComponent from "../components/LoadingComponent";
import Accordion from "react-bootstrap/Accordion";
import {eventTrack} from "../services/util";
import PaymentComponent from "../components/PaymentComponent";


export interface DiscountPromptModelComponentProps {
    onSuccess: () => any;
}

export interface DiscountPromptModelComponentState {
   email?: string
}

function DiscountPromptModelComponent(props: DiscountPromptModelComponentProps) {
    const [state, setState] = useState<DiscountPromptModelComponentState>({

    });



    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { name, value } = event.target;

        setState({
            ...state,
            [name]: value
        });
    };






    return (

            <div className='row'>
                <div className="col-sm-12 col-md-4">
                    <img
                        src={process.env.PUBLIC_URL + '/assets/img/nicha-cartoon-1.png'}
                        className="mw-100"
                        alt="Collage img"
                    />
                </div>
                <div className="col-sm-12 col-md-8">
                    <h2 className='text-center'>
                        50% Off!!!
                    </h2>
                    <h4>
                        50% off your order if you subscribe now!🎨🐕
                    </h4>
                    <h4>
                        We're pawesomely excited to have you join us!🐾 🖼
                    </h4>
                    <div className=" m-3 text-start">
                        <label className="form-label">Email*</label>
                        <input
                            type="email"
                            name="email"
                            value={state.email}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="d-grid">
                        <button className="btn btn-primary text-center " onClick={() => {

                            if(
                                !state.email ||
                                !String(state.email)
                                .toLowerCase()
                                .match(
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                )){
                               GQLService.toast({
                                   body: "Invalid Email"
                               });
                               return;
                            }
                            const res = GQLService.emailListSignup({
                                email: state.email,
                                listId: "656aacdd-706a-4e85-b324-154030b44293"
                            });
                            if (!res) {
                                GQLService.toast({
                                    body: "Something went wrong"
                                });
                                return;
                            }
                            GQLService.toast({
                                body: "Check your email",
                                bg: "info"
                            });
                            props.onSuccess();
                        }}>
                            Get 50% off!
                        </button>
                    </div>
                    <p className="fineprint m-3">
                        (*)-Unsubscribe at any time
                    </p>
                </div>

            </div>


);

}

export default DiscountPromptModelComponent;