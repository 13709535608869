import React, {useEffect, ChangeEvent, useState, useRef, MutableRefObject, RefObject} from "react";
import { GQLService } from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ImageEdit, { ImageEditComponentState } from "../components/ImageEdit";
import { dataURItoBlob, eventTrack } from "../services/util";
import { Oval } from "react-loader-spinner";
import LoadingButtonComponent, {LoadingButtonComponentHandle} from "../components/LoadingButtonComponent";
import Gallery from '../components/Gallery';
import GoodBadExamples from "../components/GoodBadExamples";
import HowItWorks from "../components/HowItWorks";
import { Link, useSearchParams } from "react-router-dom";

import {isMobile, isSafari} from 'react-device-detect';
import md5 from "md5";
import {YoloService} from "../services/YoloService";

import Cookies from 'universal-cookie';
import {GlobalModalService} from "../components/GlobalModalComponent";
const cookies = new Cookies();
const ACCEPTED_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];
export interface LandingComponentProps {
    // wizardComponent: LandingWizard;
    canSignup?: boolean
}
export interface LandingComponentStateImageState{
    extension?: string;
    uploadState?: 'pending' | 'uploading' | 'uploaded';
    name?: string,
    src?: string,
    key?: string;
    hash?: string;
    ref?: RefObject<HTMLImageElement>;
    yolo?: any;
}
export interface LandingComponentState {
    uploadStarted?: boolean;
    signupCodeTemp?: string;
    username?: string;
    email?: string;
    password?: string;
    code?: string;
    step: number;
    // uploadedImages: string[];
    uploadedImagesState: LandingComponentStateImageState[],
    showCodeInput?: boolean;
    authMode: "signup" | "login";
    selectedImageIndex: number;
    isLoading: boolean;
    signupCode?: string | null;
    signupCodeMode: 'email' | 'email_done' | 'code'
}



const Landing = (props: LandingComponentProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const presignupButtonRef = useRef<LoadingButtonComponentHandle>(null);
    const finishPhotoButtonRef = useRef<LoadingButtonComponentHandle>(null);

    useEffect(() => {
        GlobalModalService.initTimeout({
            action: () => {
                if (state.uploadStarted) {

                    return;
                }
                GlobalModalService.displayDiscountCodePrompt();
            }
        })


        return () => {
            GlobalModalService.clearTimeouts();
        }
    });
    const setLocalStorageImages = (imageIndex: number, lState: LandingComponentStateImageState | null) => {
        const imageIndexBody = getLocalStorageImagesIndex() || [];
        const key = 'img_' + imageIndex;


        imageIndexBody.push(key);
        try {
            localStorage.setItem(key, JSON.stringify(lState, null, 3));
            localStorage.setItem('img_index', JSON.stringify(imageIndexBody, null, 3));
        } catch(err: any) {
            GQLService.toast({
                body: err.message
            })
        }
        return key;
    }
    const getImageStorageLimit = (): number => {
        if (
            isMobile &&
            isSafari
        ) {
            return 8;
        }
        return 30; // 9999;
    }
    const removeLocalStorageImage = (imageKey: string) => {
        const imageIndexBody = getLocalStorageImagesIndex() || [];
        localStorage.removeItem(imageKey);
        localStorage.setItem('img_index', JSON.stringify(imageIndexBody.filter((iib) => iib !== imageKey), null, 3));
    }
    const getLocalStorageImagesIndex = (): string[] | null => {
        const json = localStorage.getItem('img_index');
        if (!json) {
            return null;
        }
        return JSON.parse(json)
    }
    const removeLocalStorageImages = () => {
        const imageIndex = getLocalStorageImagesIndex();
        if (!imageIndex) {
            return null;
        }
        for (let i = 0; i < imageIndex.length; i++ ){
            localStorage.removeItem(imageIndex[i]);
        }
        localStorage.removeItem('img_index');
    }
    const getLocalStorageImages = (): LandingComponentStateImageState[] | null => {
        const imageIndex = getLocalStorageImagesIndex();
        if (!imageIndex) {
            return null;
        }
        const res: LandingComponentStateImageState[] = [];
        for (let i = 0; i < imageIndex.length; i++ ){
            const imageJSON = localStorage.getItem(imageIndex[i]);
            if (imageJSON) {
                res[i] = JSON.parse(
                    imageJSON
                );
                res[i].key = imageIndex[i];
            }
        }
        return res;
    }
    function processImage(dataURI: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.onload = async () => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                if (!context){
                    throw new Error("Missing canvas `context`");
                }
                let width = image.width;
                let height = image.height;

                if (width <= 512 && height <= 512) {
                    // Do nothing
                   /* // Image is already smaller or equal to 512 pixels, no resizing needed
                    resolve(dataURI);
                    return;*/
                } else if (width < height) {
                    height = Math.round((height / width) * 512);
                    width = 512;
                } else {
                    width = Math.round((width / height) * 512);
                    height = 512;
                }

                canvas.width = width;
                canvas.height = height;

                context.drawImage(image, 0, 0, width, height);

                const resizedDataURI = canvas.toDataURL('image/jpeg');
                await YoloService.init();
                const res  = await YoloService.parseImage({
                    src: canvas // imageState.ref
                });
                console.log("LOOOOIK2222:", res);
                resolve({
                    resizedDataURI,
                    yolo: res
                });
            };

            image.onerror = () => {
                reject(new Error('Failed to load the image.'));
            };

            image.src = dataURI;
        });
    }
    const lState = getLocalStorageState();
    let step = lState?.step || 1;
    if (
        step > 1 &&
        !GQLService.isLoggedIn()
    ) {
        step = 1;
    }
    const [state, setState] = useState<LandingComponentState>({

        step,
        // uploadedImages: [],
        authMode: "signup",
        selectedImageIndex: -1,
        uploadedImagesState: getLocalStorageImages() || [],
        isLoading: false,
        username: '',
        password: '',
        email: '',
        signupCodeMode: 'email',
        signupCode: (GQLService.isLoggedIn() && 'x') || searchParams.get('code') || lState?.signupCode || (props.canSignup ? 'x' : null) || null,
    });

    const handleImageUpload = async (event: any) => {
        event.preventDefault();
        eventTrack(
            'handleImageUpload-' + state.step,
        );
        const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        const uploadedImages = state.uploadedImagesState;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            const extension = file.name.split('.').pop().toLowerCase();
            await new Promise<void>((resolve) => {
                reader.onload = async () => {
                    const hash = md5(reader.result as string);
                    const existing = state.uploadedImagesState.find((imageState) => imageState.hash === hash);
                    if (existing) {
                        GQLService.toast({ body: "Image has already been selected for upload" });
                        return;
                    }
                    const uploadedImagesState = state.uploadedImagesState;
                    const newIndex = uploadedImages.length;
                    // const imgRef = useRef<HTMLImageElement>(null);
                    const processedImageData =  await processImage(reader.result as string);
                    uploadedImagesState[newIndex] = {
                        name: file.name,
                        extension,
                        src: processedImageData.resizedDataURI,
                        key: 'tmpKey_' + newIndex,
                        hash,
                        yolo: processedImageData.yolo
                        // ref: imgRef
                    };
                    const hasDog = !!uploadedImagesState[newIndex].yolo.class_detect.find((cd: any) =>{
                        return cd[0] === 16;
                    });
                    uploadedImagesState[newIndex].yolo = {
                        ...uploadedImagesState[newIndex].yolo,
                        hasDog
                    }
                    //
                    const newState = { ...state, uploadedImagesState }
                    setState(newState);
                    if (state.uploadedImagesState.length <= getImageStorageLimit()) {
                        uploadedImagesState[newIndex].key = setLocalStorageImages(newIndex, uploadedImagesState[newIndex]);
                    }

                    return resolve();
                };

                reader.readAsDataURL(file);
            });
        }
    };


    const getMinPhotoCount = () => {
        if (state.step === 1) {
            return 1;
        }
        return 10;
    }
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;
        switch (name) {
            case ('username'):
                value = value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
                break;
            case('code'):
                value = value.toLowerCase().replace(/[^0-9]/g, '');
                break;
        }
        setState({ ...state, [name]: value });
    };

    const onToggleAuthMode = (authMode: "signup" | "login") => {
        setState({ ...state, authMode });
    };
    const onToggleSignupCodeMode = (signupCodeMode: "email" | "code") => {
        setState({ ...state, signupCodeMode });
    };
    const toggleSignupCodeMode = () => {
        if (state.signupCodeMode === 'email') {
            return onToggleSignupCodeMode('code');
        } else {
            return onToggleSignupCodeMode('email');
        }
    }
    const onSaveImage = (imageSrc: any) => {
        const uploadedImagesState = state.uploadedImagesState;
        uploadedImagesState[state.selectedImageIndex] =
            {
                ...uploadedImagesState[state.selectedImageIndex],
                src: imageSrc
            };
        setState({ ...state, uploadedImagesState });
    };

    const renderCurrentStep = () => {
        switch (state.step) {
            case 1:
                return renderPhotoPrompt();
            case 2:
                return renderAuth();
            case 3:
                return renderPhotoPrompt();
            /*      case 5:
                    return renderEditPhotos();
                  case 6:*/
            default:
                return renderPhotoPrompt();
        }
    };

    const getProgressBarWidth = () => Math.min(state.uploadedImagesState.length * 3.333333, 100);
    const getProgressBarColor = () => {
        const progressBarWidth = getProgressBarWidth();
        if (progressBarWidth === 0) {
            return 'grey';
        } else if (progressBarWidth <= 33) {
            return 'red';
        } else if (progressBarWidth <= 66) {
            return 'orange';
        } else {
            return 'green';
        }
    };

    function getUploadImageText() {
        if (state.step > 1 && state.uploadedImagesState.length < 10) {
            return <div>
                <p>
                    Hey, friend! 🐾🤖 Our AI machine craves pet power! <strong>we need
                        at least <b className="d-inline-block">{(10 - state.uploadedImagesState.length)}</b> more photos</strong>. But more than 20 is even better! 📸🎨
                </p>
                <p>
                    Upload some more, and we'll work our AI wizardry to make your pet's art!
                </p>
            </div>
        }
        if (state.step > 1 && state.uploadedImagesState.length < 20) {
            return <div>
                <p>
                    Woohoo! You did it! 🎉 I've got a secret
                    tip for you: if you want the absolute best results, <strong>upload at least <b className="d-inline-block">20</b> images</strong>. Else click <strong>'Continue'</strong>.
                </p>
            </div>
        }
        if (state.step > 1 && state.uploadedImagesState.length > 19) {
            return <div>
                <p>
                    Hip-hip-hooray! 🎉 You're a superstar, my friend! For absolute best results can you find a few more images and make it to <b className="d-inline-block">30?</b> 📸🌟
                </p>
                <p>
                    When you're done uploading click <strong>'Continue'</strong> to start the AI Master Artist 🎨💖
                </p>
            </div>
        }
        return '';
    }

    async function preSignup() {
        try {
            const response = await GQLService.preSignup({
                email: state.email,
                tid: cookies.get('_fprom_tid') || null
            });
            console.log(response);
            setState({
                ...state,
                signupCodeMode: 'email_done'
            })
        }catch(err) {
            if (presignupButtonRef.current) {
                presignupButtonRef.current.reset();
            }
        }

    }




    const renderPhotoPrompt = () => (
        <div>
            {state.step === 1 && (<div className="step-1 main-container">
                <div className="container-fluid">
                    <div className="container pb-5">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 text-start">
                                <h1>
                                    Your dog, <br />DrawnBy.AI
                                </h1>
                                <div className="row">
                                    <p className="col-8">
                                        So, what are you waiting for? Upload a few photos of your dog and get amazing,
                                        one-of-a-kind masterpieces DrawnBy.AI!
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/img/collage2-min.png'}
                                    className="mw-70"
                                    alt="Collage img"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
            <div className={`${!state.signupCode ? '' : 'd-none'}`}>
                <div className="container-fluid join pt-5">
                    <div className="container pb-3">
                        {/*<div className="row">
                            <div className="col-12 mb-4 text-center">
                                <h2>How do you like to join?</h2>
                            </div>
                        </div>*/}
                        <div className="row align-items-stretch">

                            <form className="col-12 col-sm mb-3 thumbnail p-4">
                                <div className="d-flex flex-column">

                                    {
                                        state.signupCodeMode === 'email_done' &&
                                        <div className="col-12 mb-4 text-center">
                                            <h2>
                                                Thank you for signing up!  Check your inbox for confirmation.
                                            </h2>
                                        </div>
                                    }
                                    {
                                        state.signupCodeMode === 'email' &&
                                        <>
                                            {/*<div className="col-12 mb-4 text-center">
                                                <h2>
                                                    Hey we reached the automated max amount of signups we
                                                </h2>
                                            </div>*/}
                                            <input
                                                type="text"
                                                name="email"
                                                className="form-control w-100 mb-4"
                                                value={state.email}
                                                onChange={handleChange}
                                                placeholder="Email"
                                            />
                                        </>
                                    }
                                    {
                                        state.signupCodeMode === 'code' &&
                                        <input
                                            type="text"
                                            name="signupCodeTemp"
                                            className="form-control w-100 mb-4"
                                            value={state.signupCodeTemp || ""}
                                            onChange={handleChange}
                                            placeholder="Code"
                                        />
                                    }
                                    {
                                        state.signupCodeMode !== 'email_done' &&
                                        <div className="container pb-3">
                                            <div className="row justify-content-center pb-3">
                                                <div className="col-12 col-sm-12 col-lg-12 col-xl-12">

                                                
                                                <LoadingButtonComponent
                                                    ref={presignupButtonRef}
                                                    className="btn btn-primary pale-lavande d-block w-100 position-relative btn-lg"
                                                    onClick={() => {
                                                        if (state.signupCodeMode === 'email') {
                                                            return preSignup();
                                                        }
                                                        setState({
                                                            ...state,
                                                            signupCode: state.signupCodeTemp
                                                        });
                                                        setLocalStorageState({
                                                            signupCode: state.signupCodeTemp
                                                        });
                                                    }}
                                                >
                                                    {
                                                        state.signupCodeMode === 'code' &&
                                                        <>Get Started</>
                                                    }
                                                    {
                                                        state.signupCodeMode === 'email' &&
                                                        <>Join the waiting list</>
                                                    }
                                                </LoadingButtonComponent>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-sm-auto pb-3">
                                                    <a
                                                        className="btn btn-outline-primary  w-100 btn-sm"
                                                        onClick={() => toggleSignupCodeMode()}
                                                    >
                                                        {
                                                            state.signupCodeMode === 'code' &&
                                                            <>Join the waiting list</>
                                                        }
                                                        {
                                                            state.signupCodeMode === 'email' &&
                                                            <>Already have a code?</>
                                                        }

                                                    </a>
                                                </div>
                                                <div className="col-12 col-sm-auto pb-3">
                                                    <Link
                                                        className="btn btn-outline-primary  w-100 btn-sm"
                                                        to="/login"
                                                    >
                                                        Log in
                                                    </Link>
                                                </div>
                                            </div>


                                        </div>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {state.step > 1 && (
                <div className="container-fluid pb-5">
                    <div className="container-fluid pt-sm-5">
                        <div className="row align-items-center">

                            <div className="col-lg-6 offset-lg-3 col-12 py-5 "><h2>For the best possible results.....</h2>
                                <ol>
                                    <li>We require uploading a minimum of 10 pictures, but for the best possible
                                        masterpieces, upload 20 to 30 high-quality photos. It really does make a HUGE
                                        difference in output quality.
                                    </li>
                                    <li>Choose a variety of shots with different angles and backgrounds. Don't include
                                        derpy photos like laying on the back or weird faces. The AI artist does best
                                        with sitting and standing poses.</li>
                                    <li>Only 1 dog per photo set, else the AI gets confused.</li>
                                    <li>Avoid photos with major obstructions, like people, furniture, or other animals.
                                        If your images have people or animals, crop them out.
                                    </li>
                                    <li>Any bows, clothing, or collars in the photos may influence the final artwork. So
                                        choose your input images wisely.
                                    </li>
                                    <li>More high quality input images will result in better output. Aim for excellence with
                                        your photos so your dog's true colors can come to life!
                                    </li>
                                </ol>
                            </div>
                            <GoodBadExamples />
                        </div>
                    </div>
                </div>
            )}

            <div className="container-fluid upload-file-section">
                <div className="container position-relative">
                    <div className={`row position-absolute justify-content-center overlay-block ${!state.signupCode ? '' : 'hide-overlay '}`}>
                        <div className="col-12 text-center content pt-5">

                            <i className="bi bi-lock"></i>
                            <h5 className="mt-5">Please join to unlock</h5>
                        </div>
                    </div>
                    <div className="row py-5">
                        <label className="col-12 dash py-5" htmlFor="uploadImg" onDragOver={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            event.dataTransfer.dropEffect = 'copy';
                            setState({
                                ...state,
                                uploadStarted: true
                            })
                        }}
                            onClick={() => {
                                setState({
                                    ...state,
                                    uploadStarted: true
                                })
                            }}
                            onDrop={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (!state.signupCode){
                                    return;
                                }

                                const files = Array.from(event.dataTransfer.files).filter(file =>
                                    ACCEPTED_TYPES.includes(file.type)
                                );
                                if (files.length === 0) {
                                    GQLService.toast({
                                        body: "Invalid File Type"
                                    })
                                    return;
                                }
                                handleImageUpload(event);
                            }}>
                            <div className="row">
                                <div className="col text-center">
                                    {state.uploadedImagesState.length < getMinPhotoCount() &&
                                        <i className={`bi bi-cloud-arrow-up cloud ${getProgressBarColor()}`}></i> ||
                                        <i className={`bi bi-cloud-check cloud ${(state.step > 3 ? getProgressBarColor() : 'green')}`}></i>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    {state.step < 3 && state.uploadedImagesState.length === 0 && (
                                        <div>
                                            <h3>Step 1: Start HERE</h3>
                                            <p className="mb-3">Drop/Upload one or more photos of your
                                                dog🐶📸</p>
                                        </div>
                                    )}
                                    {state.step < 3 && 0 < state.uploadedImagesState.length && state.uploadedImagesState.length < getMinPhotoCount() && (
                                        <h3>Please, drag more photos of your fuzzball</h3>
                                    )}
                                    {state.step < 3 && state.uploadedImagesState.length >= getMinPhotoCount() && (
                                        <div>
                                            <h3>Step 2: Great!</h3>
                                            <h4 className="mb-3">Now click "Next" or upload more photos</h4>
                                            <p>Your dog's photos will be sent to the AI master artist and
                                                make some magic happen!</p>
                                        </div>
                                    )}

                                </div>
                            </div>
                            {state.step > 1 && (
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col text-center">
                                            <h3 className="mb-5">Uploaded: {state.uploadedImagesState.length}</h3>
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div
                                            className="offset-xs-1 offset-xl-2 col-10 col-xl-8 text-center legend">
                                            <h4 className="mb-3"
                                                id="typingEffect">{getUploadImageText()}</h4>
                                        </div>
                                    </div>
                                </div>)}
                            {state.uploadedImagesState.length > 0 && (
                                <div className="container-fluid uploaded-files">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            {
                                                state.selectedImageIndex !== -1 &&
                                                state.uploadedImagesState[state.selectedImageIndex]?.src &&
                                                <ImageEdit
                                                    onModify={onSaveImage}
                                                    onSaveImage={onSaveImage}
                                                    imageSrc={state.uploadedImagesState[state.selectedImageIndex].src as string}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <div className="row justify-content-center">
                                                {state.uploadedImagesState.map((imageState, index) => (
                                                    <div className="col-auto mb-4" key={index}>
                                                        <div key={index} className="thumbnail position-relative text-end">
                                                            {
                                                                state.step > 1 &&
                                                                <div>
                                                                    {
                                                                        imageState.uploadState === 'uploading' &&
                                                                        <Oval
                                                                            height={25}
                                                                            width={25}
                                                                            wrapperClass="custom-oval every-img"
                                                                            visible={true}
                                                                            ariaLabel='oval-completed'
                                                                            strokeWidth={8}
                                                                            strokeWidthSecondary={8}
                                                                        />
                                                                    }
                                                                    {
                                                                        imageState.uploadState === 'uploaded' &&
                                                                        <Oval
                                                                            height={25}
                                                                            width={25}
                                                                            wrapperClass="custom-oval every-img loaded"
                                                                            visible={true}
                                                                            ariaLabel='oval-loading'
                                                                            strokeWidth={8}
                                                                            strokeWidthSecondary={8}
                                                                        />
                                                                    }
                                                                </div>
                                                            }
                                                            <img
                                                                src={imageState.src}
                                                                className="pet-img"
                                                                alt={`Pet Image ${index}`}
                                                            />
                                                            <div className="row justify-content-center">
                                                                <div className="col-auto d-none">
                                                                    <button type="button" className="btn btn-link"
                                                                        onClick={() => {
                                                                            eventTrack(
                                                                                'imageEditClick'
                                                                            );
                                                                            setState({ ...state, selectedImageIndex: index })
                                                                        }}
                                                                    >Edit
                                                                    </button>
                                                                </div>
                                                                {/*{
                                                                    ((imageState) => {
                                                                        return imageState.yolo.class_detect.map((a: any, i: number) => {
                                                                            return labels[imageState.yolo.class_detect[i]] + ' - ' + Math.round(imageState.yolo.scores[i] * 100) + '%';
                                                                        }).join(' - ')
                                                                    })(imageState)
                                                                }
                                                                {
                                                                    imageState.yolo?.hasDog && <>hASdOG </>
                                                                }*/}
                                                                <div className="col-auto">
                                                                    {  !state.uploadedImagesState[index].uploadState &&
                                                                    <button type="button" className="btn btn-link delete"
                                                                        onClick={() => handleImageDelete(state.uploadedImagesState[index])}
                                                                    >Delete
                                                                    </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <input
                                id="uploadImg"
                                type="file"
                                accept="image/jpg, image/jpeg, image/png"
                                className="d-none"
                                multiple
                                onChange={handleImageUpload}
                                disabled={!state.signupCode}
                            />
                            {state.step > 2 && state.uploadedImagesState.length > 0 && (
                                <div className="container">
                                    <div className="d-flex justify-content-between pt-3">
                                        <div className="scale">0</div>
                                        <div className="scale">10</div>
                                        <div className="scale">20</div>
                                        <div className="scale">30+</div>
                                    </div>
                                    <div className="container-progress-bar">
                                        <div
                                            className={`progress-bar-inner ${getProgressBarColor()}`}
                                            style={{ width: `${getProgressBarWidth()}%` }}
                                        />
                                    </div>
                                </div>
                            )}
                        </label>
                        <div className="row py-3 justify-content-center">
                            {
                                state.uploadedImagesState.length < 30 &&
                                <div className="col-auto text-center pt-1">
                                    <label className="btn btn-outline-primary" htmlFor="uploadImg">
                                        {state.step < 3 && state.uploadedImagesState.length < getMinPhotoCount() ? "Upload" : "Upload more"}
                                    </label>
                                </div>
                            }

                            {
                                state.uploadedImagesState.length >= getMinPhotoCount() && (
                                    <div className="col-auto text-center next-continue pt-1">
                                        <LoadingButtonComponent
                                            ref={finishPhotoButtonRef}
                                            disabled={state.uploadedImagesState.length < getMinPhotoCount()}
                                            className={`btn btn-primary d-inline-block position-relative`}
                                            onClick={() => finishPhoto()}
                                        >
                                            {
                                                state.step < 3 ?
                                                    "Next" :
                                                    "Continue"
                                            }
                                        </LoadingButtonComponent>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>

            {state.step === 1 && (
                <div className="container-fluid light-grey-bg pt-5">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="col text-center pb-5">
                                <h2>How it works</h2>
                            </div>
                        </div>
                        <HowItWorks />
                    </div>
                </div>
            )}
            {state.step === 1 && (
                <div className="container-fluid gallery light-grey-bg pb-5 pt-sm-5">
                    <div className="row">
                        <div className="col text-center pb-5">
                            <h2>Explore some amazing creations</h2>
                        </div>
                    </div>
                    <Gallery />
                </div>
            )}
            <div className=" fixed-bottom">
                {
                    state.uploadedImagesState.length >= getMinPhotoCount() && (
                        <div className=" navbar row justify-content-center d-block d-md-none d-lg-none d-xl-none">
                            <div className="col-auto mb-3">

                                <div className="col-auto text-center next-continue pt-1">
                                    <LoadingButtonComponent
                                        ref={finishPhotoButtonRef}
                                        disabled={state.uploadedImagesState.length < getMinPhotoCount()}
                                        className={`btn btn-primary d-inline-block position-relative`}
                                        onClick={() => finishPhoto()}
                                    >
                                        {
                                            state.step < 3 ?
                                                "Next" :
                                                "Continue"
                                        }
                                    </LoadingButtonComponent>
                                </div>

                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );

    async function finishPhoto() {
        try {
            if (state.step === 1) {
                eventTrack(
                    'finishPhoto-step-1',
                );
                setState({...state, step: state.step + 1});
                const lState = getLocalStorageState() || {};
                setLocalStorageState({
                    ...lState,
                    step: state.step + 1
                });
                return;
            }

            eventTrack(
                'finishPhoto-step-3',
            );
            let countHasDog = 0;
            for (let i = 0; i < state.uploadedImagesState.length; i++) {
                if (state.uploadedImagesState[i].yolo?.hasDog) {
                    countHasDog += 1;
                }
            }
            const markTrainingInstanceFlagged = (countHasDog < state.uploadedImagesState.length * .5);
            const res = await GQLService.quickSetupTrainingModel({
                trainingInstance: {
                  namespace: 'default',
                  name: 'Default',
                  flag: markTrainingInstanceFlagged ? 'NeedsReview' : 'None'
                },
                inferenceRequests: [
                    {
                        "prompt": {
                            "name": "Cartoon",
                            "imageCount": 6,
                            "prompt": "a super cute illustration of rvrctepppy dog in the style of (pixar animation), studio quality, highly detailed, diffuse lighting, octane render",
                            "extraRunArgs": [
                                "--cfg",
                                9,
                                "--steps",
                                40
                            ]
                        },
                        metaData: {
                            sendgridTemplateId: "d-6a3dbf6443584b41b67bd1edfe92def0"
                        }
                    },
                    {
                        "prompt": {
                            "name": "Vibrant",
                            "imageCount": 6,
                            "prompt": "portrait of rvrctepppy dog in the style of Leonid Afremov, Bright colors, vibrant colors, bold colors, strong brush strokes",
                            "extraRunArgs": [
                                "--cfg",
                                7,
                                "--steps",
                                30
                            ]
                        },
                        metaData: {
                            sendgridTemplateId: "d-6a3dbf6443584b41b67bd1edfe92def0"
                        }
                    },
                    {
                        "prompt": {
                            "name": "Oil Painting",
                            "imageCount": 6,
                            "prompt": "an oil painting portrait of rvrctepppy dog in style of vincent van gogh, intricate, elegant, masterpiece, oil on canvas, wet-on-wet technique",
                            "extraRunArgs": [
                                "--cfg",
                                9,
                                "--steps",
                                35
                            ]
                        },
                        metaData: {
                            sendgridTemplateId: "d-6a3dbf6443584b41b67bd1edfe92def0"
                        }
                    },
                    {
                        "prompt": {
                            "name": "Modern Art",
                            "imageCount": 6,
                            "prompt": "digital painting of rvrctepppy dog (art by andy warhol), concept art",
                            "extraRunArgs": [
                                "--cfg",
                                13,
                                "--steps",
                                50
                            ]
                        },
                        metaData: {
                            sendgridTemplateId: "d-6a3dbf6443584b41b67bd1edfe92def0"
                        }
                    },
                    {
                        "prompt": {
                            "name": "Pencil",
                            "imageCount": 6,
                            "prompt": "pencil illustration portrait of rvrctepppy dog, natural soft rim light, realistic, detailed, monochrome",
                            "extraRunArgs": [
                                "--cfg",
                                7,
                                "--steps",
                                30
                            ]
                        },
                        metaData: {
                            sendgridTemplateId: "d-6a3dbf6443584b41b67bd1edfe92def0"
                        }
                    },
                    {
                        "prompt": {
                            "name": "Screen Print",
                            "imageCount": 6,
                            "prompt": "screen print portrait of rvrctepppy dog style of Chuck Sperry",
                            "extraRunArgs": [
                                "--cfg",
                                13,
                                "--steps",
                                50
                            ]
                        },
                        metaData: {
                            sendgridTemplateId: "d-6a3dbf6443584b41b67bd1edfe92def0"
                        }
                    },
                    {
                        "prompt": {
                            "name": "Cowboy",
                            "imageCount": 6,
                            "prompt": "a pinhole camera portrait of rvrctepppy dog as a cowboy wearing a cowboy hat, realistic, expressive emotions, intricate textures, illusionistic detail, old west, desert mountain background",
                            "extraRunArgs": [
                                "--cfg",
                                9,
                                "--steps",
                                45
                            ]
                        },
                        metaData: {
                            sendgridTemplateId: "d-6a3dbf6443584b41b67bd1edfe92def0"
                        }
                    },
                    {
                        "prompt": {
                            "name": "The Captain",
                            "imageCount": 6,
                            "prompt": "portrait of rvrctepppy dog wearing a captain's hat, oil painting style, Northern Renaissance art, oil on canvas, wet-on-wet technique, realistic, expressive emotions, intricate textures, illusionistic detail",
                            "extraRunArgs": [
                                "--cfg",
                                9,
                                "--steps",
                                30
                            ]
                        },
                        metaData: {
                            sendgridTemplateId: "d-6a3dbf6443584b41b67bd1edfe92def0"
                        }
                    },
                    {

                        "prompt": {
                            "name": "Astronaut",
                            "imageCount": 6,
                            "prompt": "rvrctepppy dog dressed as an astronaut, portrait, illustration, interstellar background, concept art, character design, futuristic, space travel, unreal engine",
                            "extraRunArgs": [
                                "--cfg",
                                9,
                                "--steps",
                                50
                            ]
                        },
                        metaData: {
                            sendgridTemplateId: "d-6a3dbf6443584b41b67bd1edfe92def0"
                        }
                    }
                ],
                uploads: state.uploadedImagesState.map((state) => ({extension: state.extension}))
            });

            for (let i = 0; i < state.uploadedImagesState.length; i++) {
                const uploadUrl = res.uploadUrls[i];
                if (!uploadUrl) {
                    throw new Error("Missing `uploadUrl` at index: " + i);
                }
                const uploadedImagesState = state.uploadedImagesState;

                if (uploadedImagesState[i].uploadState !== 'uploaded') {
                    uploadedImagesState[i] = {
                        ...uploadedImagesState[i],
                        uploadState: 'uploading',
                    };
                    if (!state.uploadedImagesState[i].src) {
                        throw new Error("Missing `src` in ImageState");
                    }
                    const result = await fetch(uploadUrl, {
                        method: "PUT",
                        body: dataURItoBlob(state.uploadedImagesState[i].src as string),
                    });
                    uploadedImagesState[i] = {
                        ...uploadedImagesState[i],
                        uploadState: 'uploaded',
                    };
                    const newState = {
                        ...state,
                        uploadedImagesState
                    };
                    setState(newState);
                    if(i <= getImageStorageLimit()) {
                        setLocalStorageImages(i, uploadedImagesState[i]);
                    }

                }
            }

        /*    if (countHasDog < state.uploadedImagesState.length * .5) {
                GQLService.toast({
                    body: `<>
                    <p>
                        Our AI has detected some problems with your photos, they've been submitted for human review.  We'll send you an email if the human confirms a problem or when your AI art has been generated.
                    </p>
                        <p>
                            If you have any questions email hello@DrawnBy.AI or click the chat in the bottom right corner.
                            Thank your for your patience and understanding,
                            The DrawnBy.AI team
                        </p>
                    </>` // countHasDog + " < " + state.uploadedImagesState.length * .5
                });
                return;
            }
            GQLService.toast({
                body: countHasDog + " < " + state.uploadedImagesState.length * .5,
                bg: 'primary'
            });*/
            if (!markTrainingInstanceFlagged) {
                await GQLService.createTrainingModelJob({});
            }
            clearLocalStorageState();
            removeLocalStorageImages();
            // Redirect
            // navigate(`/${state.username}/reqs`);

            document.location.reload();
        }catch(err) {
            if (!finishPhotoButtonRef.current) {
                throw err;
            }
            finishPhotoButtonRef.current.reset();
        }
    }


    const renderAuth = () => (
        <div className="container-fluid main-container step-3">
            <div className="container pt-5">
                <div className="row">
                    <div className="col-12 text-center py-5">
                        <h2>Please, log in/sign up</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a
                                    className={
                                        "nav-link " + (state.authMode === "signup" ? "active" : "")
                                    }
                                    aria-current="page"
                                    href="#"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        onToggleAuthMode("signup")
                                    }}
                                >
                                    Sign up
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={
                                        "nav-link " + (state.authMode === "login" ? "active" : "")
                                    }
                                    href="#"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        onToggleAuthMode("login")
                                    }}
                                >
                                    Login
                                </a>
                            </li>
                        </ul>
                        <form className="row mt-5">
                            {state.authMode === "signup" && ( <div className="col-12 mb-3 text-start">
                                <label className="form-label">Username*</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                        >
                                            https://drawnby.ai/
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        name="username"
                                        className="form-control"
                                        value={state.username}
                                        onChange={handleChange.bind(this)}

                                    />
                                </div>
                            </div>
                            ) }
                            {state.authMode === "login" && ( <div className="col-12 mb-3 text-start">
                                <label className="form-label">Username*</label>
                                <input
                                    type="text"
                                    name="username"
                                    className="form-control"
                                    value={state.username}
                                    onChange={handleChange.bind(this)}
                                />
                            </div>
                            ) }
                            {state.authMode === "signup" && (
                                <div>
                                    <div className="col-12 mb-3 text-start">
                                        <label className="form-label">Email*</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={state.email}
                                            onChange={handleChange.bind(this)}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-12 text-center mb-3 sm-p">
                                        <h4>Password Requirements</h4>
                                        <p>Min Length 8 character</p>
                                        <p>Contains at least 1 number</p>
                                        <p>Contains at least 1 uppercase letter</p>
                                        <p>Contains at least 1 lowercase letter</p>
                                    </div>
                                </div>
                            )}

                            <div className="col-12 mb-3 text-start">
                                <label className="form-label">Password*</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={state.password}
                                    onChange={handleChange.bind(this)}
                                    className="form-control"
                                />
                            </div>

                            {state.showCodeInput && (<div className="col-12 text-center mb-3">
                                <p>Hey, dog lover! 🐾📸 Check your inbox for an email. Inside, you'll find a 6-digit
                                    activation code! 💌✨</p>

                                <p>Copy the code, come back here and paste it in that box below⬇️.</p>
                            </div>)}
                            {state.showCodeInput && (<div className="col-12 code mb-3">
                                <input
                                    type="text"
                                    name="code"
                                    value={state.code}
                                    onChange={handleChange.bind(this)}
                                    className="form-control text-center"
                                    maxLength={6}
                                />
                            </div>)}
                        </form>
                        <div className="row mt-3">
                            <div className="col-12 text-center">
                                <button
                                    className={`btn btn-primary w-100 position-relative ${state.uploadedImagesState.length === 0 ? "d-none" : "d-inline-block"}`}
                                    onClick={finishAuth}
                                    disabled={state.isLoading}
                                >
                                    {state.isLoading && (
                                        <Oval
                                            height={30}
                                            width={30}
                                            wrapperStyle={{}}
                                            wrapperClass="custom-oval"
                                            visible={true}
                                            ariaLabel='oval-loading'
                                            strokeWidth={6}
                                            strokeWidthSecondary={6}
                                        />
                                    )}
                                    <div>
                                        {state.authMode === "signup" ? <span>Sign up</span> : <span>Log in</span>}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


    const handleImageDelete = (imageState: LandingComponentStateImageState) => {

        const uploadedImagesState = state.uploadedImagesState.filter((_) => _.key !== imageState.key);
        eventTrack(
            'handleImageDelete'
        );
        const newState: LandingComponentState = { ...state, uploadedImagesState };
        setState(newState);
        if (!imageState?.key){
            return ;// throw new Error("Missing `imageState.key`");
        }
        removeLocalStorageImage(imageState.key);
    };
    const isUserNotConfirmedError = (err: Error) => {
        return err.message === "User is not confirmed."
    }

    const finishAuth = async () => {
        setState({ ...state, isLoading: true });
        eventTrack(
            'finishAuth'
        );
        try {
            if (!state.code) {
                if (state.authMode === "signup") {
                    if (!state.signupCode) {
                        throw new Error("Missing `signupCode`");
                    }
                    eventTrack(
                        'finishAuth-signup',
                    );
                    const res = await GQLService.signUp({
                        username: state.username,
                        password: state.password,
                        email: state.email,
                        signupCode: state.signupCode,
                        tid: cookies.get('_fprom_tid')
                    });
                    console.log("Res: ", res);
                    setState({ ...state, showCodeInput: true, isLoading: false });
                } else {
                    eventTrack(
                        'finishAuth-login',
                    );
                    const res = await GQLService.login({
                        username: state.username,
                        password: state.password,
                    });
                    console.log("Res: ", res);
                    setState({ ...state, step: 4, isLoading: false });
                    const lState = getLocalStorageState() || {};
                    setLocalStorageState({
                        ...lState,
                        step: 4
                    });
                }
            } else {
                eventTrack(
                    'finishAuth-finishSignUp',
                );
                const res = await GQLService.finishSignUp({
                    username: state.username,
                    password: state.password,
                    code: state.code,
                });
                console.log("Res2: ", res);

                setState({ ...state, step: 4, isLoading: false });
                const lState = getLocalStorageState() || {};
                setLocalStorageState({
                    ...lState,
                    step: 4
                });
            }
        } catch (err: any) {
            if (isUserNotConfirmedError(err)) {
                eventTrack(
                    'finishAuth-userNotConfirmedError',
                );
                setState({ ...state, showCodeInput: true, isLoading: false });
                return;
            }
            eventTrack(
                'finishAuth-error',
                err.message
            );
            console.error("Login ERROR: ", err);
            setState({ ...state, isLoading: false });
            throw err;
        }
    };


    return (
        <div>
            {renderCurrentStep()}
        </div>
    );
};
const getLocalStorageState = (): Partial<LandingComponentState> | null => {

    const json = localStorage.getItem('landing_state');
    if (!json) {
        return null;
    }
    return JSON.parse(json)
}

const setLocalStorageState = (lState: Partial<LandingComponentState> | null) => {
    localStorage.setItem('landing_state', JSON.stringify(lState, null, 3));
}
const clearLocalStorageState = () => {
    localStorage.removeItem('landing_state');
}
export default Landing;
export {
    getLocalStorageState,
    setLocalStorageState,
    clearLocalStorageState
}
