import React, { ChangeEvent, useEffect, useState } from "react";
import { GQLService } from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ImageEdit, { ImageEditComponentState } from "../components/ImageEdit";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import BreadcrumbComponent from "../components/BreadcrumbComponent";

export interface TrainingModelListComponentProps {
  // wizardComponent: HomeWizard;
}
export interface TrainingModelListState {
  loaded?: boolean;
  trainingModels: {
    _id: string,
    name: string,
    namespace: string,
    user: {
      username: string
    }
  }[]
}


const TrainingModelList = (props: TrainingModelListComponentProps) => {
  const [state, setState] = useState<TrainingModelListState>({
    trainingModels: []
  });
  const navigate = useNavigate();
  const params = useParams();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  useEffect( () => {
    if (state.loaded){
      return;
    }
    console.log("params", params);
    GQLService.listTrainingModels({
      username: params.username
    })
   .then((trainingModels: any) => {
     setState({
       ...state,
       trainingModels,
       loaded: true
     });
   });
  });
  return (
   <div className='container'>
      <div className='row'>
        <BreadcrumbComponent />
        <Link className='btn btn-lg' to={`/` + params.username + `/models/new`}>New</Link>
        <table className="table">
          <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">User</th>
          </tr>
          </thead>
          <tbody>
          {state.trainingModels.map((trainingModel) => {
            return <tr key={trainingModel._id}>
              <th scope="row">
                  {trainingModel._id}
              </th>
              <td>
                <Link to={"/" + trainingModel.user.username + "/models/" + trainingModel._id}>
                  {trainingModel.name}
                </Link>
              </td>
              <td>
                <Link to={"/" + trainingModel.user.username}>
                  {trainingModel.user.username}
                </Link>
              </td>
            </tr>
          })}

          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TrainingModelList;
