import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { GQLService } from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useNavigate, useParams } from "react-router";
import ImageDisplayComponent from "../components/ImageDisplayComponent";
import ImageUploadComponent from "../components/ImageUploadComponent";
import {dataURItoBlob} from "../services/util";
import {Link} from "react-router-dom";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import YoloComponent from "../components/YoloImageComponent";

export interface TrainingInstanceDetailComponentProps {

}
export interface TrainingInstanceDetailState {
  loaded?: boolean;
  selectedTab: 'data' | 'models';

  trainingInstance?: {
    _id?: string,
    name?: string,
    namespace?: string,
    files?: any[],
    user?: {
      username: string
    },
    trainingModelInstances?: any[]
  }
}


const TrainingInstanceDetail = (props: TrainingInstanceDetailComponentProps) => {
  const [state, setState] = useState<TrainingInstanceDetailState>({
    selectedTab: 'data'
  });
  const navigate = useNavigate();
  const params = useParams();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const parts = name.split('.');
    const entityName = parts[0];
    const entityProperty = parts[1];
    const newState: any = {
      ...state,
      trainingInstance: {
        ...state.trainingInstance
      }
    }
    newState[entityName][entityProperty] = value;
    setState(newState);
  };
  const save = async (event: React.MouseEvent<HTMLButtonElement>) =>{
    event.preventDefault();
    if (!state.trainingInstance?._id){
      // Create
      const trainingInstance = await GQLService.createTrainingInstance(state.trainingInstance);
      setState({
        ...state,
        trainingInstance: {
          ...state.trainingInstance,
          ...trainingInstance
        }
      });
      return navigate('/' + trainingInstance?.user?.username + '/instances/' + trainingInstance?.namespace);
    } else {
      // Update
      const trainingInstance = await GQLService.updateTrainingInstance({
        _id: state.trainingInstance._id,
        name: state.trainingInstance.name,
        namespace: state.trainingInstance.namespace
      });
      return navigate('/' + trainingInstance?.user?.username + '/instances/' + trainingInstance?.namespace);
    }
  }
  useEffect( () => {
    if (state.loaded){
      return;
    }
    console.log("params", params)
    if (!params.instance){
      setState({
        ...state,
        trainingInstance: {},
        loaded: true
      });
      return;
    }
    GQLService.listTrainingInstance({
      namespace: params.instance,
      username: params.username
    })
   .then((TrainingInstances: any) => {
     const trainingInstance = TrainingInstances.find((tm: any) => tm.namespace === params.instance);
     setState({
       ...state,
       trainingInstance,
       loaded: true
     });
   });
  });

  function deleteImage(s3Path: string) {
    if (!state.trainingInstance) {
      throw new Error("THis shouldn't be possible. state.trainingInstance is null.")
    }
    GQLService.deleteTrainingInstanceFile({
        trainingInstanceId: state.trainingInstance._id,
      s3Path
    })
      .then((res: any) => {
        const files = state.trainingInstance?.files || [];
        setState({
          ...state,
          trainingInstance: {
              ...state.trainingInstance,
              files: files.filter((f: any) => f.path !== s3Path)
          }
        });
      });
  }

  async function  uploadImage(uploadedImages: { file: string; extension: any }[]) {
    const res = await GQLService.getTrainingInstanceUploadUrls({
      trainingInstanceId: state.trainingInstance?._id,
      uploads: uploadedImages.map((file) => ({extension: file.extension}))
    });
    for (let i = 0; i < uploadedImages.length; i++) {
      const uploadUrl = res.uploadUrls[i];
      if (!uploadUrl) {
        throw new Error("Missing `uploadUrl` at index: " + i);
      }
      const result = await fetch(uploadUrl, {
        method: "PUT",
        body: dataURItoBlob(uploadedImages[i].file),
      });
      // document.location.reload();
/*      uploadedImagesState[i] = {
        ...uploadedImagesState[i],
        uploaded: true,
      };
      setState({
        ...state,
        uploadedImagesState
      })*/
    }
  }

  return (
   <div className='container'>
     {state.trainingInstance &&
       <div className='row'>
         <h1>{state.trainingInstance.name}</h1>
         <BreadcrumbComponent />
         <div className='container'>
           <div className='row'>
             <form className="row mt-5">
               <div className="col-12 mb-3 text-start">
                 <label className="form-label">Name*</label>
                 <input
                   type="text"
                   name="trainingInstance.name"
                   className="form-control"
                   value={state.trainingInstance.name}
                   onChange={handleChange.bind(this)}
                 />
               </div>

               <div className="col-12 mb-3 text-start">
                 <label className="form-label">Namespace*</label>
                 <input
                   type="text"
                   name="trainingInstance.namespace"
                   className="form-control"
                   value={state.trainingInstance.namespace}
                   onChange={handleChange.bind(this)}
                 />
               </div>
               <div className="col-12 mb-3 text-start">
                 <button
                   className={`btn btn-primary w-100 d-inline-block`}
                   onClick={(event) => save(event)}
                 >
                   Save
                 </button>
               </div>

             </form>
           </div>
         </div>
         <ul className="nav nav-tabs">
           <li className="nav-item">
             <a className={"nav-link " + (state.selectedTab === 'data' ? 'active' : '')} aria-current="page" href="#" onClick={ (event) => {
               event.preventDefault();
               setState({...state, selectedTab: 'data'})
             }}>Data</a>
           </li>
           <li className="nav-item">
             <a className={"nav-link " + (state.selectedTab === 'models' ? 'active' : '')}  href="#" onClick={ (event) =>{
               event.preventDefault();
               setState({...state, selectedTab: 'models'})
             }}>Models</a>
           </li>
         </ul>
         { state.selectedTab === 'data' &&
             <div className='container'>
               <div className='row'>
                 <ImageUploadComponent  onLoad={(uploadedImages) => {
                   uploadImage(uploadedImages);
                 }}/>
               </div>
               <div className='row'>
                 <h3>Data</h3>

                   {state.trainingInstance?.files?.map((file, index) => {
                     return <YoloComponent src={file.downloadUrl} key={index}>
                       <button className="btn-icon delete"
                          onClick={() => { deleteImage(file.path) }}
                       ></button>
                     </YoloComponent>
                   })}
               </div>
             </div>
         }
         { state.selectedTab === 'models' &&
             <div className='container'>
               <div className='row'>
                 <h3>Models</h3>
                 <table className="table">
                   <thead>
                   <tr>
                     <th scope="col">Id</th>
                     <th scope="col">Name</th>
                     <th scope="col">User</th>
                   </tr>
                   </thead>
                   <tbody>
                   {state.trainingInstance.trainingModelInstances?.map((trainingModelInstance) => {
                     return <tr key={trainingModelInstance._id}>
                       <th scope="row">
                         {trainingModelInstance.model._id}
                       </th>
                       <td>
                         <Link to={"/" + trainingModelInstance.model.user.username + "/models/" + trainingModelInstance.model.namespace}>
                           {trainingModelInstance.model.name}
                         </Link>
                       </td>
                       <td>
                         <Link to={"/" + trainingModelInstance.model.user.username}>
                           {trainingModelInstance.model.user.username}
                         </Link>
                       </td>
                     </tr>
                   })}

                   </tbody>
                 </table>
               </div>
             </div>
         }
       </div>
     }
    </div>
  );
};
export default TrainingInstanceDetail;
