import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import { GQLService } from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ImageEdit, { ImageEditComponentState } from "../components/ImageEdit";
import { useNavigate } from "react-router";
import LoadingButtonComponent, {LoadingButtonComponentHandle} from "../components/LoadingButtonComponent";
import {eventTrack} from "../services/util";
import {Alert} from "react-bootstrap";

export interface LoginComponentProps {
  // wizardComponent: HomeWizard;
}
export interface LoginState {
  username?: string,
  password?: string,
  authMode: 'login'
  error?: any
}


const Login = (props: LoginComponentProps) => {
  const [state, setState] = useState<LoginState>({
    authMode: 'login'
  });
  const navigate = useNavigate();
  const loadingButtonRef = useRef<LoadingButtonComponentHandle>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    switch(name) {
      case('username'):
        value = value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
      break;
    }
    setState({ ...state, [name]: value });
  };
  const finishAuth = async () => {
    eventTrack(
        'login-finishAuth',
    );
    try {
      const res = await GQLService.login({
        username: state.username,
        password: state.password,
      });
      console.log("Res: ", res);
      navigate(`/`);
    }catch(err: any) {
      if (loadingButtonRef.current) {
        loadingButtonRef.current.reset();
      }
      setState({
        ...state,
        error: err
      });
    }
  }

 /* useEffect( () => {
    GQLService.listUser({ })
   .then((users) => {
     setState({
       ...state,
       users
     });
   });
  });*/
  eventTrack(
      'login-startLogin',
  );
  return (
   <div className='container'>
     <div className="row">
       <div className="col-12 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4">
     {/*    <ul className="nav nav-tabs">
           <li className="nav-item">
             <a
               className={
                 "nav-link " + (state.authMode === "login" ? "active" : "")
               }
               href="#"
             >
               Login
             </a>
           </li>
         </ul>*/}
         <form className="row mt-5">
           <div className="col-12 mb-3 text-start">
             <label className="form-label">Username*</label>
             <input
               type="text"
               name="username"
               className="form-control"
               value={state.username}
               onChange={handleChange.bind(this)}
             />
           </div>

           <div className="col-12 mb-3 text-start">
             <label className="form-label">Password*</label>
             <input
               type="password"
               name="password"
               value={state.password}
               onChange={handleChange.bind(this)}
               className="form-control"
             />
           </div>


         </form>
         <div className="row mt-3">
           <div className="col-12 text-center">

             <LoadingButtonComponent
               ref={loadingButtonRef}
               className={`btn btn-primary w-100 d-inline-block position-relative`}
               onClick={() => finishAuth()}
             >
               Login
             </LoadingButtonComponent>
           </div>
         </div>
       </div>
     </div>
   </div>
  );
};
export default Login;
