import {loadStripe} from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
    CardElement, CardNumberElement, CardExpiryElement, CardCvcElement,
} from '@stripe/react-stripe-js';
import React, {ChangeEvent, useEffect, useState} from "react";
import {LoadingButtonComponentState} from "@/components/LoadingButtonComponent";
import {StripeElementsOptionsMode} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {GQLService} from "../services/GQLService";
import {CreatePaymentMethodCardData} from "@stripe/stripe-js/types/stripe-js/payment-intents";
import {Button} from "react-bootstrap";
import LoadingComponent from "../components/LoadingComponent";
import Accordion from "react-bootstrap/Accordion";
import {eventTrack} from "../services/util";
import PaymentComponent from "@/components/PaymentComponent";
import {GlobalModalService} from "../components/GlobalModalComponent";


export interface InlineCallToActionComponentProps {
   //  onSuccess: any;
    index?: number;
}

export interface InlineCallToActionComponentState {
    error?: any

    state: 'open' | 'locked'

    showCoupon?: boolean;
    coupon?:string;

    loaded?: boolean;

    basePrice?: number;
    appliedPrice?: number;
    couponObj?: { name: string };
}

function InlineCallToActionComponent(props: InlineCallToActionComponentProps) {
    const [state, setState] = useState<InlineCallToActionComponentState>({
        state: 'open',
        showCoupon: false
    });


    useEffect( () => {
        if (state.loaded){
            return;
        }
        GQLService.getStripeSubscriptionPrice({

        })
        .then((res) => {
            setState({
                ...state,
                basePrice: res.basePrice / 100,
                appliedPrice: res.appliedPrice / 100,
                loaded: true
            })
           res.appliedPrice
        });
    });
    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { name, value } = event.target;

        setState({
            ...state,
            [name]: value
        });
    };

    function displayBuyNow(){
        GlobalModalService.displayPaymentPage({
            onSubscribeSuccess: () => {
                window.location.reload();
            }
        });

    }




    return (
        <>
            {
                GQLService.isLoggedIn() &&
                !GQLService.hasSubscription() &&
                <>
                    {
                        (props.index === -1) &&
                        <div className="alert alert-primary m-5 " role="alert">
                            <div className="row">
                                <div className="col-sm-12 col-md-2">
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/img/nicha-cowboy-1.png'}
                                        className="mw-100"
                                        alt="Collage img"
                                    />
                                </div>
                                <div className="col-sm-12 col-md-10 text-center">
                                    <h2>
                                        Howdy Paw-tner 🤠🐾!
                                    </h2>
                                    <p>
                                        😍 Love what you see, but want to remove the watermarks 🌊?
                                    </p>
                                    <p>
                                        Let's  dance our 🐾
                                        paws over to the payment page and take care of that!
                                    </p>
                                    <p className="">
                                        (Someone's gotta buy the dog treats 🦴🐕!)
                                    </p>
                                    <div className="d-grid">
                                        <button className="btn btn-primary" onClick={displayBuyNow}>
                                            BUY Now !!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        (props.index === 0) &&
                        <div className="alert alert-primary m-5 " role="alert">
                            <div className="row">
                                <div className="col-sm-12 col-md-2">
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/img/nicha-cartoon-1.png'}
                                        className="mw-100"
                                        alt="Collage img"
                                    />
                                </div>
                                <div className="col-sm-12 col-md-10 text-center">
                                    <h2>
                                        Comically paw-esome!! 🐾
                                    </h2>
                                    <p>
                                        Ready to unleash the full "paw-sibilities" of these amazing artworks? 🎨🐕
                                    </p>
                                    <p>
                                        Your pet's AI art deserves to 🔆 shine without those obtrusive watermarks.
                                    </p>
                                    <p className="">
                                        So, fetch those payment details and make those watermarks roll over and play dead!
                                        💦🐕
                                    </p>
                                    <div className="d-grid">
                                        <button className="btn btn-primary" onClick={displayBuyNow}>
                                            BUY Now !!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        (props.index === 1) &&
                        <div className="alert alert-primary m-5 " role="alert">
                            <div className="row">
                                <div className="col-sm-12 col-md-2">
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/img/nicha-captain-1.png'}
                                        className="mw-100"
                                        alt="Collage img"
                                    />
                                </div>
                                <div className="col-sm-12 col-md-10 text-center">
                                    <h2>
                                        Ahoy Captain!⛵
                                    </h2>
                                    <p>
                                        If these artworks your first mate's tails a-waggin', then it's time to navigate these
                                        waters and bid those pesky watermarks farewell⚓
                                    </p>
                                    <p>
                                        So, hoist the  flag of 💰 payment, and let those watermarks walk the plank! 🌊
                                    </p>
                                    <div className="d-grid">
                                        <button className="btn btn-primary" onClick={displayBuyNow}>
                                            BUY Now !!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                    (props.index === 2) &&
                    <div className="alert alert-primary m-5 " role="alert">
                        <div className="row">
                            <div className="col-sm-12 col-md-2">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/img/nicha-superhero-1.png'}
                                    className="mw-100"
                                    alt="Collage img"
                                />
                            </div>
                            <div className="col-sm-12 col-md-10 text-center">
                                <h2>
                                    Art-Loving Superhero! 🖼🦸
                                </h2>
                                <p>
                                    Ready to rescue these spectacular artworks from the evil clutches of watermarks? 💪
                                </p>
                                <p>
                                    Mask up your 💰 payment details and 💥obliterate those watermarks! 🐾🐕
                                </p>
                                <div className="d-grid">
                                    <button className="btn btn-primary" onClick={displayBuyNow}>
                                        BUY Now !!
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                </>
            }
        </>

    );

}

export default InlineCallToActionComponent;