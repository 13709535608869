import React, {createRef, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import {YoloService} from "../services/YoloService";
import labels from "../services/labels.json";
export interface YoloComponentProps {
    src: string;
    alt?: string
    key?: any;
    children: React.ReactNode;
}

export interface YoloComponentState {
    loaded?: boolean;
    res?: any;
}
function YoloComponent (props: YoloComponentProps) {
    const [state, setState] = useState<YoloComponentState>({

    });
    const imgRef = useRef<HTMLImageElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    async function init() {
        await YoloService.init();
        // const imgEle = await YoloService.toHTMLImageElement(props.src);
        const imgEle = imgRef.current;
        const res  = await YoloService.parseImage({
            src: imgEle
        });
        console.log("YOLO RES: ", res)
        const threshold = 0.10;
        setState({
            ...state,
            res
        })
        renderBoxes(
            canvasRef,
            threshold,
            res.boxes,
            res.scores,
            res.class_detect
        );
    }

   /* useEffect(() => {
        if(state.loaded) {
            return;
        }
        init();
        setState({
            loaded: true
        })
    })*/



    return (
        <div className="col-auto mb-4">
            <div className="thumbnail  text-end">
                <div className="yolo-thumbnail">
                    <img ref={imgRef}
                        src={props.src}
                        className="pet-img mb-2"
                        alt={props.alt || ''}
                         crossOrigin='anonymous'
                         onLoad={() => init() }
                    />
                    <canvas width={640} height={640} ref={canvasRef} />
                </div>
                <div>
                    {
                        state.res && state.res.detections.map((detection: any, index: number) => {
                            return labels[detection[5]] + '-' + Math.round(state.res.scores[index] * 100) + '%';
                        })
                    }
                </div>
                {props.children}
            </div>
        </div>
    );
};
export default YoloComponent;


function xywh2xyxy(x: any){
    //Convert boxes from [x, y, w, h] to [x1, y1, x2, y2] where xy1=top-left, xy2=bottom-right
    var y = [];
    y[0] = x[0] - x[2] / 2  //top left x
    y[1] = x[1] - x[3] / 2  //top left y
    y[2] = x[0] + x[2] / 2  //bottom right x
    y[3] = x[1] + x[3] / 2  //bottom right y
    return y;
}

export const renderBoxes = (canvasRef: any, threshold: any, boxes_data: any, scores_data: any, classes_data: any) => {

    const ctx = canvasRef.current.getContext("2d");

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // clean canvas

    // font configs
    const font = "18px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";

    for (let i = 0; i < scores_data.length; ++i) {
        //console.log('scores_data[i]: ', scores_data[i])
        if (scores_data[i] > threshold) {
            const klass = labels[classes_data[i]];
            const score = (scores_data[i] * 100).toFixed(1);

            let [x1, y1, x2, y2] = xywh2xyxy(boxes_data[i]);

            const width = x2 - x1;
            const height = y2 - y1;

            // Draw the bounding box.
            ctx.strokeStyle = "#B033FF";
            ctx.lineWidth = 2;
            ctx.strokeRect(x1, y1, width, height);

            // Draw the label background.
            ctx.fillStyle = "#B033FF";
            const textWidth = ctx.measureText(klass + " - " + score + "%").width;
            const textHeight = parseInt(font, 10); // base 10
            ctx.fillRect(x1 - 1, y1 - (textHeight + 2), textWidth + 2, textHeight + 2);

            // Draw labels
            ctx.fillStyle = "#ffffff";
            ctx.fillText(klass + " - " + score + "%", x1 - 1, y1 - (textHeight + 2));
        }
    }
};