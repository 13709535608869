import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { GQLService } from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ImageEdit, { ImageEditComponentState } from "../components/ImageEdit";
import { useNavigate, useParams } from "react-router";
import AssociateTrainingInstanceComponent from "../components/AssociateTrainingInstanceComponent";
import {Link} from "react-router-dom";
import BreadcrumbComponent from "../components/BreadcrumbComponent";

export interface TrainingModelDetailComponentProps {

}
export interface TrainingModelDetailState {
  loaded?: boolean;
  trainingModel?: {
    _id?: string,
    name?: string,
    namespace?: string,
    user?: {
      username: string
    },
    trainingModelInstances?: any[],
    jobs?: any[]
    inferenceRequests?: any[]
  },
  displayAssociateTrainingInstance?: boolean;

}


const TrainingModelDetail = (props: TrainingModelDetailComponentProps) => {
  const [state, setState] = useState<TrainingModelDetailState>({

  });
  const navigate = useNavigate();
  const params = useParams();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const parts = name.split('.');
    const entityName = parts[0];
    const entityProperty = parts[1];
    const newState: any = {
      ...state,
      trainingModel: {
        ...state.trainingModel
      }
    }
    newState[entityName][entityProperty] = value;
    setState(newState);
  };
  const save = async (event: React.MouseEvent<HTMLButtonElement>) =>{
    event.preventDefault();
    console.log(state);
    if (!state.trainingModel?._id){
      // Create
      const trainingModel = await GQLService.createTrainingModel(state.trainingModel);
      setState({
        ...state,
        trainingModel: {
          ...state.trainingModel,
          ...trainingModel
        }
      });
      return navigate('/' + trainingModel?.user?.username + '/models/' + trainingModel?._id);
    } else {
      // Update
      const trainingModel = await GQLService.updateTrainingModel({
        _id: state.trainingModel._id,
        name: state.trainingModel.name,
        namespace: state.trainingModel.namespace
      });
      return navigate('/' + trainingModel?.user?.username + '/models/' + trainingModel?._id);
    }
  }
  useEffect( () => {
    if (state.loaded){
      return;
    }
    if (!params.model){
      setState({
        ...state,
        trainingModel: {},
        loaded: true
      });
      return;
    }
    GQLService.listTrainingModelDetailed({
      _id: params.model,
      username: params.username
    })
   .then((trainingModels: any) => {
     const trainingModel = trainingModels.find((tm: any) => tm._id === params.model);
     setState({
       ...state,
       trainingModel,
       loaded: true
     });
   });
  });

  function dissociateTrainingModelInstance(trainingModelInstance: any) {
    GQLService.deleteTrainingModelInstance(trainingModelInstance._id)
      .then((res: any) => {
        // Todo fix this hacky crap:
        document.location.reload();
      });
  }

  async function requeueInferenceRequest(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if(!state.trainingModel?._id) {
      throw new Error("Missing `state.trainingModel?._id`")
    }
    const response = await GQLService.requeueInferenceRequest(state.trainingModel?._id);
    GQLService.toast({
      body: `Queued up ${response.length} InferenceRequests`,
      bg: 'info'
    })
  }

  return (
   <div className='container'>
     {state.trainingModel &&
       <div className='row'>
         <h1>{state.trainingModel.name}</h1>
         <BreadcrumbComponent />
         <div className='container'>
           <div className='row'>
             <form className="row mt-5">
               <div className="col-12 mb-3 text-start">
                 <label className="form-label">Name*</label>
                 <input
                   type="text"
                   name="trainingModel.name"
                   className="form-control"
                   value={state.trainingModel.name}
                   onChange={handleChange.bind(this)}
                 />
               </div>

               <div className="col-12 mb-3 text-start">
                 <label className="form-label">Namespace*</label>
                 <input
                   type="text"
                   name="trainingModel.namespace"
                   className="form-control"
                   value={state.trainingModel.namespace}
                   onChange={handleChange.bind(this)}
                 />
               </div>
               <div className="col-12 mb-3 text-start">
                 <button
                   className={`btn btn-primary w-100 d-inline-block`}
                   onClick={(event) => save(event)}
                 >
                   Save
                 </button>
               </div>

               <div className="col-12 mb-3 text-start">
                 <button
                     className={`btn btn-primary w-100 d-inline-block`}
                     onClick={(event) => requeueInferenceRequest(event)}
                 >
                   Requeue Inference Requests
                 </button>
               </div>
             </form>
           </div>
         </div>

         <div className='container'>
           <div className='row'>
             <h3>Instances</h3>
             { !state.displayAssociateTrainingInstance &&
               <button
                 className={`btn btn-primary w-100 d-inline-block`}
                 onClick={(event)=> setState({
                   ...state,
                   displayAssociateTrainingInstance: true
                 }) }
               >
                 Associate TrainingInstance
               </button>
             }
             {
               state.displayAssociateTrainingInstance &&
                 <AssociateTrainingInstanceComponent formId="1" trainingModel={state.trainingModel} onSuccess={(trainingModelInstance) => {
                   const trainingModelInstances = state.trainingModel?.trainingModelInstances || [];
                   trainingModelInstances.push(trainingModelInstance);
                   setState({
                     ...state,
                     trainingModel:{
                       ...state.trainingModel,
                       trainingModelInstances
                     }

                   });

                 }}></AssociateTrainingInstanceComponent>

             }
             <table className="table">
               <thead>
               <tr>
                 <th scope="col">Id</th>
                 <th scope="col">Name</th>
                 <th scope="col">User</th>
               </tr>
               </thead>
               <tbody>
               {state.trainingModel.trainingModelInstances?.map((trainingModelInstance) => {
                 return <tr key={trainingModelInstance._id}>
                   <th scope="row">
                     {trainingModelInstance.instance._id}
                   </th>
                   <td>
                     <Link to={"/" + trainingModelInstance.instance.user.username + "/instances/" + trainingModelInstance.instance._id}>
                       {trainingModelInstance.instance.name}
                     </Link>
                   </td>
                   <td>
                     <Link to={"/" + trainingModelInstance.instance.user.username}>
                       {trainingModelInstance.instance.user.username}
                     </Link>
                   </td>
                   <td>
                       <img src={trainingModelInstance.instance.files[0]?.downloadUrl} />
                   </td>
                   <td>
                     <button className='btn btn-sm' onClick={() => dissociateTrainingModelInstance(trainingModelInstance) }>
                       De-Associate
                     </button>
                   </td>
                 </tr>
               })}

               </tbody>
             </table>
           </div>
         </div>


         <div className='container'>
           <div className='row'>
             <h3>Jobs</h3>
             <Link
                 className={`btn btn-primary w-100 d-inline-block`}
                 to={`/${params.username}/models/${params.model}/jobs/new`}
             >
               New TrainingModelJob
             </Link>
             <table className="table">
               <thead>
               <tr>
                 <th scope="col">Id</th>
                 <th scope="col">AWS Batch Id</th>
               </tr>
               </thead>
               <tbody>
               {state.trainingModel.jobs?.map((job) => {
                 return <tr key={job._id}>
                   <th scope="row">
                     <Link
                         to={`/${params.username}/models/${params.model}/jobs/${job._id}`}
                     >
                       {job._id}
                     </Link>
                   </th>
                   <td>
                     <a href={'https://us-east-1.console.aws.amazon.com/batch/home?region=us-east-1#jobs/detail/'+ job.awsBatchJobId} target="_blank">
                       {job.awsBatchJobId}
                     </a>
                   </td>
                 </tr>
               })}

               </tbody>
             </table>
           </div>
         </div>



         <div className='container'>
           <div className='row'>
             <h3>Inference Requests</h3>
             <Link
                 className={`btn btn-primary w-100 d-inline-block`}
                 to={`/${params.username}/models/${params.model}/reqs/new`}
             >
               New InferenceRequest
             </Link>
             <table className="table">
               <thead>
               <tr>
                 <th scope="col">Id</th>
                 <th scope="col">Name</th>
                 <th scope="col">AWS Batch Id</th>
                 <th scope="col">Created At</th>
                 <th scope="col">User</th>
               </tr>
               </thead>
               <tbody>
               {state.trainingModel.inferenceRequests?.map((inferenceRequest) => {
                 return <tr key={inferenceRequest._id}>
                   <th scope="row">
                     <a href={`/${state.trainingModel?.user?.username}/models/${state.trainingModel?._id}/reqs/${inferenceRequest._id}`}>
                       {inferenceRequest._id}
                     </a>
                   </th>
                   <td>
                     {
                       inferenceRequest.prompt?.name || inferenceRequest.prompt?.prompt
                     }
                   </td>
                   <td>
                     <a href={'https://us-east-1.console.aws.amazon.com/batch/home?region=us-east-1#jobs/detail/'+ inferenceRequest.awsBatchJobId} target="_blank">
                       {inferenceRequest.awsBatchJobId}
                     </a>
                   </td>
                   <td>
                     {inferenceRequest.createdAt}
                   </td>
                   <td>
                     {inferenceRequest.user?.username}
                   </td>
                 </tr>
               })}

               </tbody>
             </table>
           </div>
         </div>



       </div>
     }
    </div>
  );
};
export default TrainingModelDetail;
