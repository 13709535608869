import React, {useState, useEffect} from "react";
import {GQLService} from "../services/GQLService";

export interface ImageUploadComponentProps {
    onLoad(arg0: { file: string; extension: string; }[]): unknown;

}

export interface ImageUploadComponentState {
    uploadedImages: string[];
    uploadedImagesState: {
        extension?: string;
        uploaded?: boolean;
        name?: string
    }[],
}

function ImageUploadComponent(props: ImageUploadComponentProps) {
    const [state, setState] = useState<ImageUploadComponentState>({
        uploadedImages: [],
        uploadedImagesState: []
    });
    const handleImageUpload = async (event: any) => {
        event.preventDefault();
        const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
       const uploadedImages: any = [];
       const promises = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            const extension = file.name.split('.').pop().toLowerCase();
            promises.push(new Promise((resolve) => {
                reader.onload = () => {
                    uploadedImages.push({
                        file: reader.result,
                        extension
                    });
                    return resolve(null);
                };
            }));

            reader.readAsDataURL(file);
        }
        await Promise.all(promises);
        props.onLoad(uploadedImages);
    };
    return (
        <div className="row py-5">
            <label className="col-12 dash py-5" htmlFor="uploadImg" onDragOver={(event) => {
                event.preventDefault();
                event.stopPropagation();
                event.dataTransfer.dropEffect = 'copy';
            }}
                   onDrop={(event) => {
                       event.preventDefault();
                       event.stopPropagation();
                       handleImageUpload(event);
                   }}>
                <div className="row align-items-center justify-content-center">
                    <div className="col-auto text-end">
                        <img
                            src={process.env.PUBLIC_URL + '/assets/svg/pale-lavende-upload.svg'}
                            className="cloud"
                            alt="Upload file icon"
                        />
                    </div>
                    <div className="col-auto text-start">
                       <h3>Upload photos of your best friend</h3>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12 text-center">
                        <label className="btn btn-outline-primary" htmlFor="uploadImg">
                            Upload
                        </label>
                    </div>
                </div>
                <input
                    id="uploadImg"
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    className="d-none"
                    multiple
                    onChange={handleImageUpload}
                />
            </label>
        </div>
    );
}
export default ImageUploadComponent;