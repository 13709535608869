import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {GQLService} from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ImageEdit, {ImageEditComponentState} from "../components/ImageEdit";
import {useNavigate} from "react-router";
import LoadingButtonComponent, {LoadingButtonComponentHandle} from "../components/LoadingButtonComponent";
import {eventTrack} from "../services/util";
import {Alert} from "react-bootstrap";
import {Link} from "react-router-dom";
import Cookies from 'universal-cookie';
import {isChrome, isMobile, isSafari} from "react-device-detect";

const cookies = new Cookies();

export interface TestPageProps {
    // wizardComponent: HomeWizard;
}

export interface TestPageState {
    loaded?: boolean;
    newKey?: string;
    newValue?: string;
    cookies: any;
}

const ITEMS_PER_PAGE = 100;

const TestPage = (props: TestPageProps) => {
    const [state, setState] = useState<TestPageState>({
        cookies: cookies.getAll()
    });
    const navigate = useNavigate();
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState({...state, [name]: value});
    };
    /* useEffect( () => {
       if (state.loaded){
         return;
       }
       refreshState();
     });
     const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
       const { name, value } = event.target;
       setState({ ...state, [name]: value });
     };

     async function refreshState(){
       let query: any = {
         // username: GQLService.getDecodedJWTToken().username,
         pagination: {
           limit: ITEMS_PER_PAGE,
           skip: (0) * ITEMS_PER_PAGE,
           orderBy: "userId",
           direction: -1
         }
       };

       const inferenceResults = await GQLService.getDownVotedInferenceResults(query);
       let groupedInferenceResults: any = {};
       for (const inferenceResult of inferenceResults) {
         groupedInferenceResults[inferenceResult.request.model._id] = groupedInferenceResults[inferenceResult.request.model._id] || {
           model: inferenceResult.request.model,
           inferenceResults: []
         };
         groupedInferenceResults[inferenceResult.request.model._id].inferenceResults.push(inferenceResult);
       }
       setState({
         ...state,
         loaded: true,
         inferenceResults: groupedInferenceResults
       })
     }

   */
    return (
        <div className="container mt-5">
            <div className='row'>
                <h3>
                Mobile Info:

                {
                    isMobile &&
                    <span>isMobile</span>
                }
                {
                    isChrome &&
                    <span>isChrome</span>
                }
                {
                    isSafari &&
                    <span>isSafari</span>
                }
                </h3>
            </div>
            <div className="row">
                <h2>Local Storage</h2>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Key</th>
                        <th scope="col">Value</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.keys(localStorage).map((key: any) => {
                            return <tr key={key}>
                                <th scope="row">
                                    {key}
                                </th>

                                <td>
                              <textarea className="form-control">
                              {localStorage.getItem(key)}
                              </textarea>
                                </td>
                                <td>
                                    <button className='btn btn-danger btn-sm' onClick={() => {
                                        localStorage.removeItem(key);
                                        setState({
                                            ...state,
                                            newValue: "",
                                            newKey: ""
                                        });
                                    }}>
                                        Remove
                                    </button>
                                </td>

                            </tr>
                        })}
                        <tr>
                            <th scope="row">
                                <input className='form-control' value={state.newKey} onChange={handleChange} name='newKey'/>
                            </th>

                            <td>
                                <input className='form-control' value={state.newValue} onChange={handleChange}
                                       name='newValue'/>
                            </td>
                            <td>
                                <button className='btn btn-primary btn-sm' onClick={() => {
                                    if (!state.newKey) {
                                        throw new Error("Name it");
                                    }
                                    if (!state.newValue) {
                                        throw new Error("Give me a value");
                                    }
                                    localStorage.setItem(state.newKey, state.newValue);
                                    setState({
                                        ...state,
                                        newValue: "",
                                        newKey: ""
                                    });
                                }}>
                                    Add
                                </button>
                            </td>

                        </tr>
                    </tbody>
                </table>

            </div>
            <div className="row">
                <h2>Cookies</h2>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Key</th>
                        <th scope="col">Value</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                       Object.keys(state.cookies).map((key: any) => {
                            return <tr key={key}>
                                <th scope="row">
                                    {key}
                                </th>

                                <td>
                              <textarea className="form-control">
                              {state.cookies[key]}
                              </textarea>
                                </td>
                                <td>
                                    <button className='btn btn-danger btn-sm' onClick={() => {
                                        cookies.remove(key);
                                        setState({
                                            ...state,
                                            cookies: cookies.getAll()
                                        });
                                    }}>
                                        Remove
                                    </button>
                                </td>

                            </tr>
                        })}
                    <tr>
                        <th scope="row">
                            <input className='form-control' value={state.newKey} onChange={handleChange} name='newKey'/>
                        </th>

                        <td>
                            <input className='form-control' value={state.newValue} onChange={handleChange}
                                   name='newValue'/>
                        </td>
                        <td>
                            <button className='btn btn-primary btn-sm' onClick={() => {
                                if (!state.newKey) {
                                    throw new Error("Name it");
                                }
                                if (!state.newValue) {
                                    throw new Error("Give me a value");
                                }
                               cookies.set(state.newKey, state.newValue);
                                setState({
                                    ...state,
                                    newValue: "",
                                    newKey: "",
                                    cookies: cookies.getAll()
                                });
                            }}>
                                Add
                            </button>
                        </td>

                    </tr>
                    </tbody>
                </table>

            </div>

        </div>
    );
};
export default TestPage;
