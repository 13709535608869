import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import { GQLService } from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ImageEdit, { ImageEditComponentState } from "../components/ImageEdit";
import { useNavigate } from "react-router";
import LoadingButtonComponent, {LoadingButtonComponentHandle} from "../components/LoadingButtonComponent";
import {eventTrack} from "../services/util";
import {Alert} from "react-bootstrap";
import {Link} from "react-router-dom";

export interface AdminPageProps {
  // wizardComponent: HomeWizard;
}
export interface AdminPageState {
  loaded?: boolean;
  inferenceResults?: any;

  trainingInstances?: any
}
const ITEMS_PER_PAGE = 100;

const AdminPage = (props: AdminPageProps) => {
  const [state, setState] = useState<AdminPageState>({

  });
  const navigate = useNavigate();
  useEffect( () => {
    if (state.loaded){
      return;
    }
    refreshState();
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  async function refreshState(){
    let query: any = {
      // username: GQLService.getDecodedJWTToken().username,
      pagination: {
        limit: ITEMS_PER_PAGE,
        skip: (0) * ITEMS_PER_PAGE,
        orderBy: "userId",
        direction: -1
      }
    };

    const variables = {
      input: query,
      listFlaggedTrainingInstanceInput:{
        flag: "NeedsReview"
      }
    }
    const res = await GQLService.getAdminPage(variables);
    const inferenceResults = res.getDownVotedInferenceResults;
    let groupedInferenceResults: any = {};
    for (const inferenceResult of inferenceResults) {
      groupedInferenceResults[inferenceResult.request.model._id] = groupedInferenceResults[inferenceResult.request.model._id] || {
        model: inferenceResult.request.model,
        inferenceResults: []
      };
      groupedInferenceResults[inferenceResult.request.model._id].inferenceResults.push(inferenceResult);
    }
    setState({
      ...state,
      loaded: true,
      inferenceResults: groupedInferenceResults,
      trainingInstances: res.listFlaggedTrainingInstance
    })
  }


  async function queueForShuffle(event: React.MouseEvent<HTMLButtonElement>, username: string, inferenceResult: any) {
    // const selectedInferenceResult = getSelectedInferenceResult();
    inferenceResult.queuedForRegenerationDate = new Date();
    await GQLService.updateInferenceResult({
      _id: inferenceResult._id,
      queuedForRegenerationDate: inferenceResult.queuedForRegenerationDate
    });
    const inferenceResults = state.inferenceResults;
    if (!inferenceResults) {
      throw new Error("Missing `state.inferenceResults`");
    }
    const i = inferenceResults[username]?.findIndex((ir: any) => ir._id === inferenceResult._id);
    inferenceResults[username][i] = inferenceResult;
    setState({
      ...state,
      inferenceResults
    });
  }
  async function requeueInferenceRequest(event: React.MouseEvent<HTMLButtonElement>, modelId: string) {
    event.preventDefault();
    if(!modelId) {
      throw new Error("Missing `modelId`")
    }
    const response = await GQLService.requeueInferenceRequest(modelId);
    GQLService.toast({
      body: `Queued up ${response.length} InferenceRequests`,
      bg: 'info'
    })
  }
  async function queueTrainingJob(event: React.MouseEvent<HTMLAnchorElement>, trainingInstance: any) {
   /* const updatedTrainingInstance = await GQLService.updateTrainingInstance({
      _id: state.trainingInstance._id,
      name: state.trainingInstance.name,
      namespace: state.trainingInstance.namespace
    });*/
    for(const trainingModelInstance of trainingInstance.trainingModelInstances) {
        await GQLService.createTrainingModelJob({
          trainingModelId: trainingModelInstance.model._id
        });
    }
    const updatedTrainingInstance = await GQLService.updateTrainingInstance({
      _id: trainingInstance._id,
      name: trainingInstance.name,
      namespace: trainingInstance.namespace,
      flag: 'None'
    });
    let trainingInstances = state.trainingInstances;
    trainingInstances = trainingInstances.map((trainingInstance: any) => {
      if (trainingInstance._id !== updatedTrainingInstance._id) {
        return trainingInstance;
      }
      return updatedTrainingInstance;
    });
    setState({
      ...state,
      trainingInstances
    });

  }
  async function rejectTrainingInstance(event: React.MouseEvent<HTMLAnchorElement>, trainingInstance: any) {
    const updatedTrainingInstance = await GQLService.updateTrainingInstance({
      _id: trainingInstance._id,
      name: trainingInstance.name,
      namespace: trainingInstance.namespace,
      flag: 'Rejected'
    });
    let trainingInstances = state.trainingInstances;
    trainingInstances = trainingInstances.map((trainingInstance: any) => {
      if (trainingInstance._id !== updatedTrainingInstance._id) {
        return trainingInstance;
      }
      return updatedTrainingInstance;
    });
    setState({
      ...state,
      trainingInstances
    });
  }
  async function resetTrainingInstance(event: React.MouseEvent<HTMLAnchorElement>, trainingInstance: any) {
    const updatedTrainingInstance = await GQLService.resetTrainingInstance({
      trainingInstanceId: trainingInstance._id,
/*      name: trainingInstance.name,
      namespace: trainingInstance.namespace,
      flag: 'None'*/
    });
    let trainingInstances = state.trainingInstances;
    trainingInstances = trainingInstances.map((trainingInstance: any) => {
      if (trainingInstance._id !== updatedTrainingInstance._id) {
        return trainingInstance;
      }
      return updatedTrainingInstance;
    });
    setState({
      ...state,
      trainingInstances
    });
  }

  return (
      <div className="container mt-5">
        <h2>
          Flagged TrainingInstances
        </h2>
        <table className="table">
          <thead>
          <tr>
            <th scope="col">Prompt</th>
            <th scope="col">Action</th>
            <th scope="col">Image</th>
          </tr>
          </thead>
          <tbody>
          {
            state.trainingInstances &&
            state.trainingInstances.map((trainingInstance: any) => {
              return <tr key={trainingInstance._id}>
                <th scope="row">
                  {
                    trainingInstance.user &&
                    <Link to={"/" + trainingInstance.user.username + "/instances/" + trainingInstance.namespace}>
                      {trainingInstance.user.username} - {trainingInstance.namespace}
                    </Link>
                  }
                </th>

                <td>
                  <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                      {trainingInstance.flag}
                    </button>
                    <ul className="dropdown-menu">
                      {
                          trainingInstance.flag === "NeedsReview" &&
                          <li>
                            <a className="dropdown-item" href="#"  onClick={(event) => queueTrainingJob(event, trainingInstance)}>Approve</a>
                          </li>
                      }
                      {
                          trainingInstance.flag === "NeedsReview" &&
                          <li>
                            <a className="dropdown-item" href="#" onClick={(event) => rejectTrainingInstance(event, trainingInstance)}>Reject</a>
                          </li>
                      }
                      {
                          trainingInstance.flag === "NeedsReview" &&
                          <li>
                            <a className="dropdown-item" href="#" onClick={(event) => resetTrainingInstance(event, trainingInstance)}>Reset</a>
                          </li>
                      }
                    </ul>
                  </div>

                </td>
                <td>
                  {
                    trainingInstance.files &&
                    trainingInstance.files.map((file: any) => {
                      return <img className="thumbnail"width={100} src={file.downloadUrl} />
                    })
                  }

                </td>

              </tr>
            })}

          </tbody>
        </table>









        {
          state.inferenceResults &&
          Object.keys(state.inferenceResults).map((modelId) => {
            return <div className="row" key={modelId}>
                <h2>
                  <Link to={"/" + state.inferenceResults[modelId].model.user.username}>
                    {state.inferenceResults[modelId].model.user.username} - {state.inferenceResults[modelId].model.name}
                  </Link>
                  <button className="btn btn-sm btn-info" onClick={(event) => requeueInferenceRequest(event, modelId)}>
                    Send it
                  </button>
                </h2>
                <table className="table">
                  <thead>
                  <tr>
                    <th scope="col">Prompt</th>
                    <th scope="col">Action</th>
                    <th scope="col">Image</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    state.inferenceResults[modelId].inferenceResults.map((inferenceResult: any) => {
                    return <tr key={inferenceResult._id}>
                      <th scope="row">
                        {
                            inferenceResult.request.user &&
                            <Link to={"/" + inferenceResult.request.user.username + "/reqs/" + inferenceResult.request._id + '/images/' + inferenceResult._id}>
                              {inferenceResult.request.prompt.name || inferenceResult.request.prompt.prompt}
                            </Link>
                        }
                      </th>

                      <td>
                        {
                            !inferenceResult.queuedForRegenerationDate ?
                                <button className="btn btn-sm btn-info" onClick={(event) => queueForShuffle(event, modelId, inferenceResult)}>
                                  Queue For Reshuffle
                                </button> :
                                <>
                                  Queued For Reshuffle
                                </>
                        }
                      </td>
                      <td>
                        <img src={inferenceResult.url256} />
                      </td>

                    </tr>
                  })}

              </tbody>
            </table>

          </div>
        })}
      </div>
  );
};
export default AdminPage;
