const GoodBadExamples = () => {
  return (
    <div className="row pt-5 justify-content-center">
      <div className="col-12 col-lg-6 text-right">
        <div className="row mx-0">
          <div className="col-12 mb-3 d-flex align-items-center">
            <div>
              <i className="bi bi-check-circle green check-icon"></i>
            </div>
            <h4 className="mb-0 ms-2">Good pics</h4>
          </div>
        </div>
        <div className="row mx-0 justify-content-center">
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/good1.png'}
              className="pet-img"
              alt=""
            />
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/good2.png'}
              className="pet-img"
              alt=""
            />
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/good3.png'}
              className="pet-img"
              alt=""
            />
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/good4.png'}
              className="pet-img"
              alt=""
            />
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/good5.png'}
              className="pet-img"
              alt=""
            />
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/good6.png'}
              className="pet-img"
              alt=""
            />
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/good7.png'}
              className="pet-img"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div className="row mx-0">
          <div className="col-12 mb-3 d-flex align-items-center">
            <div>
              <i className="bi bi-x-circle red check-icon"></i>
            </div>
            <h4 className="mb-0 ms-2">Bad pics</h4>
          </div>
        </div>
        <div className="row mx-0 justify-content-center">
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/bad1.png'}
              className="pet-img"
              alt=""
            />
            <p>Other dog</p>
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/bad2.png'}
              className="pet-img"
              alt=""
            />
            <p>Not good light</p>
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/bad3.png'}
              className="pet-img"
              alt=""
            />
            <p>Cut off, unnatural pose</p>
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/bad4.png'}
              className="pet-img"
              alt=""
            />
            <p>Obstruction</p>
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/bad5.png'}
              className="pet-img"
              alt=""
            />
            <p>Lots of tongue</p>
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/bad6.png'}
              className="pet-img"
              alt=""
            />
            <p>Laying on Back</p>
          </div>
          <div className="col-auto mb-3 bad-good-pics">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/good-bad-examples/bad7.png'}
              className="pet-img"
              alt=""
            />
            <p>From Above</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoodBadExamples;
