const galleryImages = [
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/astronaut_1.png`,
        category: "Astronaut",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/astronaut_2.png`,
        category: "Astronaut",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/cartoon_1.png`,
        category: "Cartoon",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/cartoon_2.png`,
        category: "Cartoon",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/hat_1.png`,
        category: "Hat",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/hat_2.png`,
        category: "Hat",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/modern_art_1.png`,
        category: "Modern Art",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/modern_art_2.png`,
        category: "Modern Art",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/oil_painting_1.png`,
        category: "Oil Painting",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/oil_painting_2.png`,
        category: "Oil Painting",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/pencil_1.png`,
        category: "Pencil",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/pencil_2.png`,
        category: "Pencil",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/screenprint_1.png`,
        category: "Screen Print",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/screenprint_2.png`,
        category: "Screen Print",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/the_captain_1.png`,
        category: "The Captain",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/the_captain_2.png`,
        category: "The Captain",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/vibrant_1.png`,
        category: "Vibrant",
    },
    {
        url: `${process.env.PUBLIC_URL}/assets/img/gallery/vibrant_2.png`,
        category: "Vibrant",
    },
];

export default galleryImages;