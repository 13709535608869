import React, { ChangeEvent, useEffect, useState } from "react";
import { GQLService } from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ImageEdit, { ImageEditComponentState } from "../components/ImageEdit";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export interface UserListComponentProps {
  // wizardComponent: HomeWizard;
}
export interface UserListState {
  loaded?: boolean;
  users: {
    _id: string,
    username: string,
    email: string,
    stripeSubscription?: any
    deletedAt?: boolean,
    selected?: boolean
  }[]
}


const UserList = (props: UserListComponentProps) => {
  const [state, setState] = useState<UserListState>({
    users: []
  });
  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  useEffect( () => {
    if (state.loaded){
      return;
    }

    GQLService.listUser({ })
   .then((users) => {
     setState({
       ...state,
       loaded: true,
       users
     });
   });
  });

  async function deleteUser(event: any, user: {_id: string}) {
    event.preventDefault();
   /* const users = await GQLService.listUser({username: params.username});
    if (users.length !== 1) {
      throw new Error("Incorrect user count: " + users?.length);
    }*/
    const res = await GQLService.deleteUser( user._id);
    console.log("deleteUser res: ", res);
    const users = state.users.map((u) => {
      if (u._id !== user._id) {
        return u;
      }
      return {
        ...u,
        deletedAt: true
      }
    });
    setState({
      ...state,
      users
    });
  }
  const handleToggleSelected = (event: React.ChangeEvent<HTMLInputElement>, user: any) => {
    const users = state.users.map((u) => {
      if (u._id !== user._id) {
        return u;
      }
      return {
        ...u,
        selected: !user.selected
      }
    });
    setState({
      ...state,
      users
    });
  };
  function getSelectedUserIds() {
    const selectedUserIds = state.users.filter((u) => u.selected)
        .map((u)=> u._id);
    return selectedUserIds;
  }
  async function bulkQueueInferenceRequest(event: React.MouseEvent<HTMLAnchorElement>) {
    const selectedUserIds = getSelectedUserIds();
    navigate('/admin/bulk/inferencerequests?user_ids=' + selectedUserIds.join(','));
  }

  return (
   <div className='container'>
      <div className='row'>
        <div className="dropdown">
          <button className="btn btn-secondary dropdown-toggle" type="button"
                  id={"userOptions"}
                  data-bs-toggle="dropdown" aria-expanded="false">
            Bulk Actions
          </button>
          <ul className="dropdown-menu dropdown-menu-dark"
              aria-labelledby={"userOptions"}>
            <li>
              <a className="dropdown-item" href="#"
                 onClick={(event) => bulkQueueInferenceRequest(event)}>
                Queue Inference Request
              </a>
            </li>

          </ul>
        </div>
      </div>
      <div className='row'>
        <table className="table">
          <thead>
          <tr>
            <th scope="col">Selected</th>
            <th scope="col">Id</th>
            <th scope="col">Username</th>
            <th scope="col">Subscription</th>
          </tr>
          </thead>
          <tbody>
          {state.users.map((user) => {
            return <tr key={user._id} className={user.deletedAt ? 'table-danger' : ''}>
              <td>
                <input
                    type="checkbox"
                    className="form-check-input"
                    checked={user.selected}
                    onChange={(event) => handleToggleSelected(event, user)}
                />
              </td>
              <th scope="row">
                <Link to={"/" + user._id}>
                  {user.email}
                </Link>
              </th>
              <td>
                <Link to={"/" + user.username}>
                  {user.username}
                </Link>
              </td>
              <td>
                {
                  user.stripeSubscription && new Date(user.stripeSubscription?.cancel_at * 1000).toString()
                }
              </td>
              <td>
                <Link to={"/" + user.username + '/models'}>
                  Models
                </Link>
              </td>
              <td>
                <Link to={"/" + user.username + '/instances'}>
                  Instances
                </Link>
              </td>
              <td>
                <Link to={"/" + user.username + '/reqs'}>
                  Requests
                </Link>
              </td>
              <td>
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button"
                          id={"userOptions"}
                          data-bs-toggle="dropdown" aria-expanded="false">
                    Admin
                  </button>
                  <ul className="dropdown-menu dropdown-menu-dark"
                      aria-labelledby={"userOptions"}>
                    <li>
                      <a className="dropdown-item" href="#"
                         onClick={(event) => deleteUser(event, user)}>
                        Delete
                      </a>
                    </li>

                  </ul>
                </div>
              </td>
            </tr>
          })}

          </tbody>
        </table>
      </div>
    </div>
  );
};
export default UserList;
