const HowItWorks = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-4 text-center mb-5">
        <div className="thumbnail d-flex flex-column h-100 px-4">
          <div className="how-it-works-icon one">
            <i className="bi bi-cloud-arrow-up"></i>
          </div>
          <h3 className="mb-5">1. Upload</h3>
          <p className="desc pb-4">
            Upload the absolute best pictures of your beloved pet. Choose ones that truly capture their adorable essence.
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-4 text-center mb-5">
        <div className="thumbnail d-flex flex-column h-100 px-4">
          <div className="how-it-works-icon two">
            <i className="bi bi-brush"></i>
          </div>
          <h3 className="mb-5">2. The AI artist works it's magic</h3>
          <p className="desc pb-4">
            Our supercharged AI will get to know every unique feature of your pet. Within minutes, it'll be ready to work its magic and generate an endless array of world class artworks.
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-4 text-center mb-5">
        <div className="thumbnail d-flex flex-column h-100 px-4">
          <div className="how-it-works-icon three">
            <i className="bi bi-share"></i>
          </div>
          <h3 className="mb-5">3. Download & Share!</h3>
          <p className="desc pb-4">
            Keep an eye on your inbox! We'll shoot you an email as soon as the process is complete. We encourage you to download and share your favorites with the world!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
