import { useState, useEffect } from "react";
import galleryImages from "./Images";

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const images = galleryImages;

  const handleSlideChange = (selectedIndex: number) => {
    if (!transitioning) {
      setActiveIndex(selectedIndex);
      setTransitioning(true);
    }
  };

  const handleTransitionEnd = () => {
    setTransitioning(false);
  };

  useEffect(() => {
    const carouselInterval = setInterval(() => {
      if (!transitioning) {
        setActiveIndex((activeIndex + 1) % images.length);
        setTransitioning(true);
      }
    }, 5000);

    return () => {
      clearInterval(carouselInterval);
    };
  }, [activeIndex, images.length, transitioning]);

  return (
    <div
      id="mostPopularCarousel"
      className="carousel carousel-dark slide carousel-fade"
      data-bs-interval="false"
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="carousel-indicators">
        {images.map((image: { url: string; category: string }, index: number) => (
          <button
            key={index}
            type="button"
            data-bs-target="#mostPopularCarousel"
            data-bs-slide-to={index.toString()}
            className={index === activeIndex ? "active" : ""}
            onClick={() => handleSlideChange(index)}
          ></button>
        ))}
      </div>
      <div className="carousel-inner thumbnail pb-3">
        {images.map((image: { url: string; category: string }, index: number) => (
          <div
            key={index}
            className={`carousel-item ${index === activeIndex ? "active" : ""}`}
          >
            <img src={image.url} className="d-block pet-img" alt={`Image ${index + 1}`} />
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#mostPopularCarousel"
        data-bs-slide="prev"
        onClick={() =>
          handleSlideChange((activeIndex - 1 + images.length) % images.length)
        }
        disabled={transitioning}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#mostPopularCarousel"
        data-bs-slide="next"
        onClick={() => handleSlideChange((activeIndex + 1) % images.length)}
        disabled={transitioning}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
