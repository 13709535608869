import {gql} from "@apollo/client";
import client from "./Apollo";

import {
    signUp,
    finishSignUp,
    login,
    quickSetupTrainingModel,
    createTrainingModelJob,
    listUser,
    listTrainingModel,
    createTrainingModel,
    updateTrainingModel,
    listTrainingInstance,
    createTrainingInstance,
    updateTrainingInstance,
    listTrainingModelJob,
    listTrainingModelDetailed,
    listInferenceRequest,
    createInferenceRequest,
    refresh,
    deleteInferenceRequest,
    createTrainingModelInstance,
    deleteTrainingModelInstance,
    deleteTrainingInstanceFile,
    getTrainingInstanceUploadUrls,
    listSignupCode,
    createSignupCode,
    preSignup,
    createPaymentIntent,
    createStripePaymentMethod,
    quickSetupStripeSubscription,
    homePageLoggedOut,
    updateInferenceResult,
    requeueInferenceRequest,
    getDownVotedInferenceResults,
    getStripeSubscriptionPrice,
    getAdminPage,
    homePageLoggedIn,
    resetTrainingInstance,
    emailListSignup, deleteUser, batchCreateInferenceRequest
} from "./graphql";
import {decodeToken, isExpired} from "react-jwt";
import {LayoutComponentState, LayoutToast} from "@/components/Layout";

export class GQLService {
    private static layoutState?: LayoutComponentState;
    private static layoutSetState?: (value: (((prevState: LayoutComponentState) => LayoutComponentState) | LayoutComponentState)) => void

    public static isLoggedIn() {
        const token = localStorage.getItem('accessToken');
        return !!token;
    }

    public static async checkAuth() {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            return;
        }
        const isMyTokenExpired = isExpired(token);
        if (!isMyTokenExpired) {
            return;
        }
        localStorage.removeItem("accessToken");
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            throw new Error("Missing `refreshToken`");
        }
        const decodedToken: any = decodeToken(token);
        const query = {
            username: decodedToken.username,
            refreshToken
        }
        return client
            .mutate({
                mutation: gql(refresh),
                variables: {
                    input: query,
                },
            })
            .then((response) => {
                const res = response?.data?.refresh;
                localStorage.setItem("accessToken", res.accessToken);
                // localStorage.setItem("refreshToken", res.refreshToken);
                return res;
            })
            .catch(GQLService.catchError);
    }

    public static signUp(query: any) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        return client
            .mutate({
                mutation: gql(signUp),
                variables: {
                    input: query,
                },
            })
            .then((response) => {
                return response?.data?.signUp;
            })
            .catch(GQLService.catchError);
    }

    public static finishSignUp(query: any) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        return client
            .mutate({
                mutation: gql(finishSignUp),
                variables: {
                    input: query,
                },
            })
            .then((response) => {
                const res = response?.data?.finishSignUp;
                localStorage.setItem("accessToken", res.accessToken);
                localStorage.setItem("refreshToken", res.refreshToken);
                localStorage.setItem("user", JSON.stringify(res.user, null, 3));
                if (GQLService.layoutSetState) {
                    GQLService.layoutSetState({
                        ...GQLService.layoutState,
                        isLoggedIn: true
                    });
                }
                return res;
            })
            .catch(GQLService.catchError);
    }

    public static login(query: any) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        return client
            .mutate({
                mutation: gql(login),
                variables: {
                    input: query,
                },
            })
            .then((response) => {
                const res = response?.data?.login;
                localStorage.setItem("accessToken", res.accessToken);
                localStorage.setItem("refreshToken", res.refreshToken);
                localStorage.setItem("user", JSON.stringify(res.user));
                if (GQLService.layoutSetState) {
                    GQLService.layoutSetState({
                        ...GQLService.layoutState,
                        isLoggedIn: true
                    });
                }
                return res;
            })
            .catch(GQLService.catchError);
    }

    public static async quickSetupTrainingModel(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(quickSetupTrainingModel),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);

        return response?.data?.quickSetupTrainingModel;

    }

    public static async createTrainingModelJob(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(createTrainingModelJob),
                variables: {
                    input: query,
                },
            })
            .catch(GQLService.catchError);
        return response?.data?.createTrainingModelJob;

    }

    public static async listUser(query: any, queryOverride?: string) {
        await this.checkAuth()

        const response = await client
            .query({
                query: gql(queryOverride || listUser),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);

        return response?.data?.listUser;

    }

    public static async deleteUser(deleteUserId: string) {
        await this.checkAuth()

        const response = await client
            .query({
                query: gql(deleteUser),
                variables: {
                    deleteUserId
                },

            })
            .catch(GQLService.catchError);

        return response?.data?.deleteUser;

    }

    public static async listSignupCode(query: any) {
        await this.checkAuth()

        const response = await client
            .query({
                query: gql(listSignupCode),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);

        return response?.data?.listSignupCode;

    }

    public static async getStripeSubscriptionPrice(query: any) {
        await this.checkAuth()

        const response = await client
            .query({
                query: gql(getStripeSubscriptionPrice),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);

        return response?.data?.getStripeSubscriptionPrice;

    }

    public static async listTrainingModels(query: any) {
        await this.checkAuth()

        const response = await client
            .query({
                query: gql(listTrainingModel),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.listTrainingModel;

    }

    public static async listTrainingModelDetailed(query: any) {
        await this.checkAuth()

        const response = await client
            .query({
                query: gql(listTrainingModelDetailed),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);

        return response?.data?.listTrainingModel;

    }

    public static async listTrainingInstance(query: any) {
        await this.checkAuth()

        const response = await client
            .query({
                query: gql(listTrainingInstance),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.listTrainingInstance;

    }

    public static async listTrainingModelJob(query: any) {
        await this.checkAuth()

        const response = await client
            .query({
                query: gql(listTrainingModelJob),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);

        return response?.data?.listTrainingModelJob;

    }

    public static async listInferenceRequest(query: any) {
        await this.checkAuth()

        const response = await client
            .query({
                query: gql(listInferenceRequest),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.listInferenceRequest;

    }

    public static async createTrainingModel(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(createTrainingModel),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.createTrainingModel;

    }

    public static async updateTrainingModel(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(updateTrainingModel),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);

        return response?.data?.updateTrainingModel;

    }

    public static async batchCreateInferenceRequest(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(batchCreateInferenceRequest),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.batchCreateInferenceRequest;

    }
    public static async createTrainingInstance(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(createTrainingInstance),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.createTrainingInstance;

    }

    public static async updateTrainingInstance(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(updateTrainingInstance),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.updateTrainingInstance;
    }
    public static async resetTrainingInstance(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(resetTrainingInstance),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.resetTrainingInstance;
    }

    public static async emailListSignup(query: { email: string, listId: string }) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(emailListSignup),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.emailListSignup;
    }

    public static async createInferenceRequest(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(createInferenceRequest),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.createInferenceRequest;

    }

    public static async createTrainingModelInstance(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(createTrainingModelInstance),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.createTrainingModelInstance;

    }

    public static async createSignupCode(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(createSignupCode),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.createSignupCode;

    }

    public static async preSignup(query: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(preSignup),
                variables: {
                    input: query,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.preSignup;

    }

    public static async deleteInferenceRequest(deleteInferenceRequestId: string) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(deleteInferenceRequest),
                variables: {
                    deleteInferenceRequestId: deleteInferenceRequestId,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.deleteInferenceRequest;

    }

    public static async deleteTrainingModelInstance(deleteTrainingModelInstanceId: string) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(deleteTrainingModelInstance),
                variables: {
                    deleteTrainingModelInstanceId: deleteTrainingModelInstanceId,
                },

            })
            .catch(GQLService.catchError);
        return response?.data?.deleteTrainingModelInstance;

    }

    public static async deleteTrainingInstanceFile(input: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(deleteTrainingInstanceFile),
                variables: {input},

            })
            .catch(GQLService.catchError);
        return response?.data?.deleteTrainingModelInstance;

    }

    public static async createPaymentIntent(input: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(createPaymentIntent),
                variables: {input},

            })
            .catch(GQLService.catchError);
        return response?.data?.createPaymentIntent;

    }

    public static async createStripePaymentMethod(input: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(createStripePaymentMethod),
                variables: {input},

            })
            .catch(GQLService.catchError);
        return response?.data?.createStripePaymentMethod;

    }
    public static async getHomePageLoggedOutData(input: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(homePageLoggedOut),
                variables: {input},

            })
            .catch(GQLService.catchError);
        return response?.data;

    }
    public static async getHomePageLoggedInData(variables: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(homePageLoggedIn),
                variables,

            })
            .catch(GQLService.catchError);
        return response?.data;

    }
    public static async updateInferenceResult(input: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(updateInferenceResult),
                variables: {input},
            })
            .catch(GQLService.catchError);
        return response?.data;

    }
    public static async requeueInferenceRequest(trainingModelId: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(requeueInferenceRequest),
                variables: {trainingModelId},
            })
            .catch(GQLService.catchError);
        return response?.data.requeueInferenceRequest;

    }
    public static async getDownVotedInferenceResults(trainingModelId: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(getDownVotedInferenceResults),
                variables: {trainingModelId},
            })
            .catch(GQLService.catchError);
        return response?.data.getDownVotedInferenceResults;

    }
    public static async getAdminPage(variables: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(getAdminPage),
                variables: variables,
            })
            .catch(GQLService.catchError);
        return response?.data;

    }

    public static async quickSetupStripeSubscription(input: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(quickSetupStripeSubscription),
                variables: {input},

            })
            .catch(GQLService.catchError);
        const res = response?.data?.quickSetupStripeSubscription;
        localStorage.setItem("user", JSON.stringify(res.user));
        return res;

    }

    public static user() {
        const userJSON = localStorage.getItem("user");
        if (!userJSON) {
            return null;
        }
        return JSON.parse(userJSON);
    }

    public static hasSubscription() {
        const user = this.user();
        if (!user) {
            return false;
        }
        if (!user.stripeSubscription) {
            return false;
        }
        if (user.stripeSubscription.cancel_at * 1000 < Date.now()) {
            return false;
        }
        return true;
    }

    public static async getTrainingInstanceUploadUrls(input: any) {
        await this.checkAuth()

        const response = await client
            .mutate({
                mutation: gql(getTrainingInstanceUploadUrls),
                variables: {input},

            })
            .catch(GQLService.catchError);
        return response?.data?.getTrainingInstanceUploadUrls;

    }
    public static getShareUrl(options: { username :string, inferenceRequestId?: string, inferenceResultId?: string}): string {

        const host = document.location.host;
        let url = `${document.location.protocol}//${host}/${options.username}`;

        if (options.inferenceRequestId) {
            url += `/reqs/${options.inferenceRequestId}`;
            if (options.inferenceResultId) {
                url = `${url}/images/${options.inferenceResultId}`;
            }
        }

        return url;

    }

    static logout() {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        if (GQLService.layoutSetState) {
            GQLService.layoutSetState({
                ...GQLService.layoutState,
                isLoggedIn: false
            });
        }
    }

    static getDecodedJWTToken(): any {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            return null;
        }
        return decodeToken(accessToken);
    }
    static hasRole(testRole: 'admin') {
        const jwt = GQLService.getDecodedJWTToken();
        if(
            !jwt ||
            !jwt['cognito:groups']
        ) {
            return false;
        }
        const foundRole = jwt['cognito:groups'].find((r: string) => r === testRole);
        return !!foundRole;
    }
    static setLayoutStateFunction(state: LayoutComponentState, setState: (value: (((prevState: LayoutComponentState) => LayoutComponentState) | LayoutComponentState)) => void) {
        GQLService.layoutState = state;
        GQLService.layoutSetState = setState;
    }

    static catchError(res: any) {
        if (GQLService.layoutSetState) {
            let alertText = JSON.stringify(res, null, 3);
            if (res.graphQLErrors && res.graphQLErrors.length > 0) {
                alertText = res.graphQLErrors.map((e: any) => e.message).join('  |  ');
                GQLService.toast({
                    body: alertText
                });
            }
            if (res.networkError?.result?.errors?.length > 0) {
                alertText = res.networkError.result.errors.map((e: any) => e.message).join('  |  ');
                GQLService.toast({
                    body: alertText
                });
            }

        }
        throw res;

    }
    static toast(toast: LayoutToast) {
        if (!toast.id) {
            toast.id = Date.now() + '_' + Math.floor(Math.random() * 9999);
        }
        if (
            !GQLService.layoutState ||
            !GQLService.layoutSetState
        ) {
            return;
        }
        GQLService.layoutSetState({
            ...GQLService.layoutState,
            toasts: [toast].concat(GQLService.layoutState?.toasts || [])
        });

    }
    static hideToast(toast: LayoutToast) {
        if (
            !GQLService.layoutState ||
            !GQLService.layoutSetState
        ) {
            return;
        }
        GQLService.layoutSetState({
            ...GQLService.layoutState,
            toasts: (GQLService.layoutState?.toasts || []).filter((t) => t.id !== toast.id)
        });
    }
}