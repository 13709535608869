import React, {useEffect, useState} from "react";
import {GQLService} from "../services/GQLService";
export interface AssociateTrainingInstanceComponentProps{
    trainingModel?: {
        _id?: string,
        namespace?: string;
       //  username?: string;
        userId?: string;
        user?: any;
    },
    trainingInstance?: {
        _id?: string;
        namespace?: string;
        // username?: string;
        userId?: string;
        user?: any;
    },
    formId: string

    onSuccess(trainingModelInstance: any): void;
}

export interface AssociateTrainingInstanceComponentState extends AssociateTrainingInstanceComponentProps{
    loaded?: boolean;
    users?: any[];
    trainingModels?: any[];
    trainingInstances?: any[];
}
function AssociateTrainingInstanceComponent(props: AssociateTrainingInstanceComponentProps) {

    const [state, setState] = useState<AssociateTrainingInstanceComponentState>({
        ...props
    });

    useEffect( () => {
        if (state.loaded){
            return;
        }

        GQLService.listUser({ })
            .then((users: any) => {
                setState({
                    ...state,
                    loaded: true,
                    users
                });
            });
    });

/*    async function deleteInferenceRequest(event: React.MouseEvent<HTMLAnchorElement>) {
        const res = await GQLService.deleteInferenceRequest(inferenceRequest._id);
        document.location.reload(); // TODO: Fix this hacky crap
    }*/

    function setTrainingModelUsername(user: any) {
        const trainingModel = state.trainingModel || {};
        GQLService.listTrainingModels({
            username: user.username
        })
        .then((trainingModels: any) => {
            setState({
                ...state,
                trainingModel: {
                    ...trainingModel,
                    userId: user._id,
                    user: {
                        ...trainingModel.user,
                        ...user
                    }
                },
                trainingModels
            });
        });

    }
    function setTrainingInstanceUsername(user: any) {
        const trainingInstance = state.trainingInstance || {};
        GQLService.listTrainingInstance({
            username: user.username
        })
            .then((trainingInstances: any) => {
                setState({
                    ...state,
                    trainingInstance: {
                        ...trainingInstance,
                        userId: user._id,
                        user: {
                           ... trainingInstance.user,
                           ... user
                        }
                    },
                    trainingInstances
                });
            });

    }

    function setTrainingModel(trainingModel: any) {
        const existingTrainingModel = state.trainingModel || {};
        setState({
            ...state,
            trainingModel: {
                ...existingTrainingModel,
                ...trainingModel
            }
        });
    }

    function setTrainingInstance(trainingInstance: any) {
        const existingTrainingInstance = state.trainingInstance || {};
        setState({
            ...state,
            trainingInstance: {
                ...existingTrainingInstance,
                ...trainingInstance
            }
        });
    }

    function addTrainingModelInstance(event: React.MouseEvent<HTMLButtonElement>) {
        if (!state.trainingModel?._id) {
            throw new Error("Missing `trainingModel._id`")
        }
        if (!state.trainingInstance?._id) {
            throw new Error("Missing `trainingInstance._id`")
        }
        GQLService.createTrainingModelInstance({
            trainingModelId: state.trainingModel._id,
            trainingInstanceId: state.trainingInstance._id
        })
        .then((trainingModelInstance: any) => {
            console.log("trainingModelInstance", trainingModelInstance);
            props.onSuccess(trainingModelInstance);
        });
    }

    return (
        <form>
            <div className="form-row">
                <div className="col">
                    Model
                </div>
                <div className="col">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button"
                                id={"AssociateTrainingInstanceComponent_" + state.formId + "_trainingModelUsername"}
                                data-bs-toggle="dropdown" aria-expanded="false">
                            {
                                state.trainingModel?.user?.username
                            }
                        </button>
                        <ul className="dropdown-menu dropdown-menu-dark"
                            aria-labelledby={"AssociateTrainingInstanceComponent_" + state.formId + "_trainingModelUsername"}>
                            {
                                state.users && state.users.map((user) => {
                                    return <li key={user._id}>
                                        <a className="dropdown-item" href="#"
                                           onClick={(event) => setTrainingModelUsername(user)}>
                                            {user.username}
                                        </a>
                                    </li>
                                })
                            }

                        </ul>
                    </div>
                </div>
                <div className="col">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button"
                                id={"AssociateTrainingInstanceComponent_" + state.formId + "_trainingModelId"}
                                data-bs-toggle="dropdown" aria-expanded="false">
                            {
                                state.trainingModel?.namespace
                            }
                        </button>
                        <ul className="dropdown-menu dropdown-menu-dark"
                            aria-labelledby={"AssociateTrainingInstanceComponent_" + state.formId + "_trainingModelId"}>
                            {
                                state.trainingModels && state.trainingModels.map((trainingModel) => {
                                    return <li key={trainingModel._id}>
                                        <a className="dropdown-item" href="#"
                                           onClick={(event) => setTrainingModel(trainingModel)}>
                                            {trainingModel.namespace}
                                        </a>
                                    </li>
                                })
                            }

                        </ul>
                    </div>
                </div>
            </div>



            <div className="form-row">
                <div className="col">
                    Instance
                </div>
                <div className="col">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button"
                                id={"AssociateTrainingInstanceComponent_" + state.formId + "_trainingInstanceUsername"}
                                data-bs-toggle="dropdown" aria-expanded="false"
                        >
                            {
                                state.trainingInstance?.user?.username
                            }
                        </button>
                        <ul className="dropdown-menu dropdown-menu-dark"
                            aria-labelledby={"AssociateTrainingInstanceComponent_" + state.formId + "_trainingInstanceUsername"}>
                            {
                                state.users && state.users.map((user) => {
                                    return <li key={user._id}>
                                        <a className="dropdown-item" href="#"
                                           onClick={(event) => setTrainingInstanceUsername(user)}>
                                            {user.username}
                                        </a>
                                    </li>
                                })
                            }

                        </ul>
                    </div>
                </div>
                <div className="col">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button"
                                id={"AssociateTrainingInstanceComponent_" + state.formId + "_trainingModelId"}
                                data-bs-toggle="dropdown" aria-expanded="false">
                            {
                                state.trainingInstance?.namespace
                            }
                        </button>
                        <ul className="dropdown-menu dropdown-menu-dark"
                            aria-labelledby={"AssociateTrainingInstanceComponent_" + state.formId + "_trainingModelId"}>
                            {
                                state.trainingInstances && state.trainingInstances.map((trainingInstance) => {
                                    return <li key={trainingInstance._id}>
                                        <a className="dropdown-item" href="#"
                                           onClick={(event) => setTrainingInstance(trainingInstance)}>
                                            {trainingInstance.namespace}
                                        </a>
                                    </li>
                                })
                            }

                        </ul>
                    </div>
                </div>
            </div>
            <button
                className={`btn btn-primary w-100 d-inline-block`}
                onClick={(event)=> addTrainingModelInstance(event) }
            >
                Associate TrainingModelInstance
            </button>
        </form>
    );
}
export default AssociateTrainingInstanceComponent;