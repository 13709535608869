import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal'
import PaymentComponent from "../components/PaymentComponent";
import DiscountPromptModelComponent from "../components/DiscountPromptModelComponent";
import AfterInferenceModelComponent from "../components/AfterInferenceModelComponent";
export interface GlobalModalComponentProp  {

}

export interface GlobalModalComponentState {
    isLoading?:boolean;

    show?:boolean;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    body?: React.ReactNode;
    className?: string;
}
class GlobalModalService{
    public static setState?: any;
    public static timeouts?: any = {};
    public static initTimeout(options: { key?: string, action: () => any, duration?: number }) {
        console.log("initTimeout");
        if(!options.key) {
            options.key = 'default';
        }
        if (GlobalModalService.timeouts[options.key]) {
            clearTimeout(GlobalModalService.timeouts[options.key]);
            delete(GlobalModalService.timeouts[options.key]);
        }
        GlobalModalService.timeouts[options.key] = setTimeout(
            options.action,
        options.duration || 15000
        );
    }
    public static clearTimeouts() {
        console.log("clearTimeouts");
        Object.keys(GlobalModalService.timeouts).forEach((key) => {
            clearTimeout(GlobalModalService.timeouts[key]);
            delete(GlobalModalService.timeouts[key]);
        });

    }
    public static close() {
        if(!GlobalModalService.setState) {
            return;
        }
        GlobalModalService.setState({ show: false });
    }
    public static displayPaymentPage(options: { onSubscribeSuccess?: any }) {
        GlobalModalService.setState({
            className: "modal-lg inferest-result",
            show: true,
            header: <></>,


            body: <div className="container-fluid carousel-dark">
                <div className='row'>
                    <h2 className='text-center'>
                        Great! Lets get started
                    </h2>
                    <ul>
                        <li>
                            Download any/all images for the next 30 days
                        </li>
                        <li>
                            Images are 512 px X 512 px
                        </li>
                        <li>
                            NO obtrusive watermarks.

                        </li>
                    </ul>
                </div>
                <div className="row">
                    <PaymentComponent onSuccess={(res: any) =>  options?.onSubscribeSuccess(res) }/>
                </div>
                <div className='row'>
                    <div className='p-4 text-center'>
                        <p className='fineprint'>
                            By pressing ‘pay’ you understand and agree to our <a className='fineprint' href={process.env.PUBLIC_URL + '/PrivacyPolicy.html'}>Privacy Policy</a> and <a className='fineprint' href={process.env.PUBLIC_URL + '/DataPolicy.html'}>Data Policies</a>
                        </p>
                        <p className='fineprint'>
                            Questions?  Click the chat icon in bottom right corner or email at: <a className='fineprint' href='mailto:hello@drawnby.ai'>hello@drawnby.ai</a>
                        </p>
                    </div>
                </div>


            </div>
        });
    }
    public static displayDiscountCodePrompt() {
        GlobalModalService.setState({
            className: "modal-lg inferest-result",
            show: true,
            header: <></>,


            body: <div className="container-fluid">
                <DiscountPromptModelComponent onSuccess={() => GlobalModalService.close() } />
            </div>
        });
    }
    public static displayAfterInferenceModal() {
        GlobalModalService.setState({
            className: "modal-lg inferest-result",
            show: true,
            header: <></>,


            body: <div className="container-fluid">
                <AfterInferenceModelComponent />
            </div>
        });
    }
}
function GlobalModalComponent(props: GlobalModalComponentProp) {
    const [state, setState] = useState<GlobalModalComponentState>({

    });
    GlobalModalService.setState = setState;
    return (
        <Modal  show={state.show} onHide={() => setState({show: false})} className={state.className}>
            {
                state.header &&
                <Modal.Header closeButton>
                    {state.header}
                </Modal.Header>
            }

            {
                state.body &&
                <Modal.Body>
                    {state.body}
                </Modal.Body>
            }
            {
                state.footer &&
                <Modal.Footer>
                    {state.footer}
                </Modal.Footer>
            }
        </Modal>
    );
}
export default GlobalModalComponent;
export { GlobalModalService }

