
import { Oval } from "react-loader-spinner";
import Countdown from 'react-countdown';
import Typewriter from "typewriter-effect";
import { useEffect, useState } from "react";
import Carousel from "./Carousel";



function WaitingComponent() {

  const [startCountdown, setStartCountdown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartCountdown(true);
    }, 11000);

    return () => {
      clearTimeout(timer);
    };
  }, []);


  return (
    <div className="waitingComponent">
      <div className="container-fluid light-grey-bg waiting-text">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">

                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .pauseFor(500)
                      .typeString("<span>Our AI is super excited to get to know your furry friend...</span>")
                      .pauseFor(2000)
                      .deleteChars(3)
                      .typeString("<span>, but it needs a little time to...</span>")
                      .pauseFor(3000)
                      .typeString("<br />")
                      .typeString("<span><strong> Please, give us a minimum of 20 minutes</strong>,</span>")
                      .pauseFor(2000)
                      .typeString("<span> or a bit more...</span>")
                      .pauseFor(2000)
                      .deleteChars(3)
                      .typeString("<span> if there's someone ahead of you in the queue.</span>")
                      .pauseFor(1000)
                      .typeString("<br />")
                      .typeString("<span> We'll send an email as soon as your amazing pet-inspired masterpieces are completed! Woof-tastic fun awaits!</span>")
                      .pauseFor(1000000000000000)
                      .start();
                  }}
                  options={{
                    delay: 30,
                    loop: false,
                    autoStart: true,
                  }}
                />

            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12 text-center position-relative timer">
              <Oval
                height={100}
                width={100}
                wrapperStyle={{}}
                wrapperClass="justify-content-center oval delay-11"
                visible={true}
                ariaLabel='oval-loading'
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
              {startCountdown && (
                <Countdown
                  date={Date.now() + 1200000}
                  renderer={({ minutes, seconds }) => (
                    <span>
                      {minutes}:{String(seconds).padStart(2, '0')}
                    </span>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid light-grey-bg">
        <div className="carousel-container">
          <div className="row align-items-center pt-5">
            <div className="offset-1 col-10 offset-lg-2 col-lg-4 offset-xl-3 col-xl-3">
              <div className="row">
                <div className="col-12">
                  <p className="mb-0">Meanwhile,</p>
                  <h3>while you eagerly await your doggy delights, here's a sneak peek at some of our other fur-tastic, tail-wagging masterpieces!</h3>
                </div>
              </div>
            </div>
            <div className="offset-1 col-10 offset-lg-0 col-lg-3 pt-lg-5 pb-5 mb-5">
              <div className="row pb-5">
                <div className="col-12 d-flex justify-content-center">
                <Carousel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default WaitingComponent;