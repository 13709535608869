import React, {useState, useRef, useEffect} from "react";
import {GQLService} from "../services/GQLService";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {eventTrack} from "../services/util";
import {useParams} from "react-router";
import InferenceRequestDetailComponent from "./InferenceRequestDetailComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "../components/LoadingComponent";
import {GlobalModalService} from "../components/GlobalModalComponent";
import InlineCallToActionComponent from "../components/InlineCallToActionComponent";


export interface InferenceRequestListComponentProps {
    onSelect?: any;
    // refreshState: () => Promise<any>;

    query: any;


    inferenceResultId?: string;

    filter?: (inferenceRequest: any) => any
}

export interface InferenceRequestListComponentState {
    loaded?: boolean;
    inferenceRequests?: any[];
    page: number;
    hasMore: boolean;
}
const ITEMS_PER_PAGE = 8;
function InferenceRequestListComponent(props: InferenceRequestListComponentProps) {
    const params = useParams();
    const [state, setState] = useState<InferenceRequestListComponentState>({
        page: 0,
        hasMore: true
    });
    useEffect(() => {
        if (state.loaded) {
            return;
        }
        (async () => {
            const inferenceRequests = await fetchData({
                page: 0
            });
            let newState = {
                ...state,
                loaded: true,
                hasMore: (inferenceRequests.length > 0),
                inferenceRequests,
                page: 0
            };
            setState(newState);
        })();
    });

    async function fetchData(options: {page:number, skipSetPage?: boolean}) {
        let query: any = {
            ...props.query,
            pagination: {
                limit: ITEMS_PER_PAGE,
                skip: (options.page) * ITEMS_PER_PAGE,
                orderBy: "createdAt",
                direction: -1
            }
        };
        /*if (params.username) {
            query.username = params.username;
        }*/
        let inferenceRequests = await GQLService.listInferenceRequest(query);
        if (props.filter) {
            inferenceRequests = inferenceRequests.filter(props.filter);
        }
        return inferenceRequests;

    }
    async function loadNextPage() {
        const page = state.page + 1;
        let inferenceRequests = await fetchData({
            page
        });
        let stateInferenceRequests: any[] = [];
        if (state.inferenceRequests) {
            stateInferenceRequests = state.inferenceRequests.map((inferenceRequest) => {
                const newInferenceRequest =  inferenceRequests.find((ir: any) => ir._id === inferenceRequest._id);
                if (newInferenceRequest) {
                    return newInferenceRequest;
                }
                return inferenceRequest;
            });
            inferenceRequests = inferenceRequests.filter((ir: any) => {
                return !stateInferenceRequests.find((ir2) => ir._id === ir2._id);
            });
        }
        let newState = {
            ...state,
            loaded: true,
            hasMore: (inferenceRequests.length > 0),
            inferenceRequests: stateInferenceRequests.concat(inferenceRequests),
            page
        };
        setState(newState);

    }
    async function refreshAll() {
        let inferenceRequests: any[] = [];
        for(let i = 0; i <= state.page; i++) {
            const newInferenceRequests = await fetchData({
                page: i,
                skipSetPage: true
            });
            inferenceRequests = inferenceRequests.concat(newInferenceRequests)
        }
       /* let stateInferenceRequests: any[] = [];
        if (state.inferenceRequests) {
            stateInferenceRequests = state.inferenceRequests.map((inferenceRequest) => {
                const newInferenceRequest =  inferenceRequests.find((ir: any) => ir._id === inferenceRequest._id);
                if (newInferenceRequest) {
                    return newInferenceRequest;
                }
                return inferenceRequest;
            });
            inferenceRequests = inferenceRequests.filter((ir: any) => {
                return !stateInferenceRequests.find((ir2) => ir._id === ir2._id);
            });
        }*/
        let newState = {
            ...state,
            loaded: true,
            hasMore: (inferenceRequests.length > 0),
            inferenceRequests,
        };
        setState(newState);
    }
    function onPullDownRefresh() {

    }
    function renderBody() {
        const res: any[] = [];
        if (!state.inferenceRequests) {
            return res;
        }
        for(let i = 0; i < state.inferenceRequests.length; i++) {
            const inferenceRequest = state.inferenceRequests[i];
            const remainder = i % 4;
            if (remainder === 3) {
                res.push(<InlineCallToActionComponent key={'inline_' + i} index={Math.floor(i/4)} />)
            }
            let overrideInferenceResultId = null;
            if (
                inferenceRequest.results.length > 0 &&
                i === 0
            ) {
                overrideInferenceResultId  = inferenceRequest.results[0]._id;
            }
            res.push(
                <InferenceRequestDetailComponent refreshState={refreshAll}
                      key={inferenceRequest._id}
                      inferenceRequest={inferenceRequest}
                      inferenceResultId={props.inferenceResultId || overrideInferenceResultId} />
            );
        }



        return res;
    }

    return (
        <div>
            {!state.loaded && <LoadingComponent /> }
            {
                state.inferenceRequests &&
                <InfiniteScroll
                    dataLength={state.inferenceRequests.length} //This is important field to render the next data
                    next={loadNextPage}
                    hasMore={state.hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{textAlign: 'center'}}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                    // below props only if you need pull down functionality
                    refreshFunction={onPullDownRefresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                        <h3 style={{textAlign: 'center'}}>&#8595; Pull down to refresh</h3>
                    }
                    releaseToRefreshContent={
                        <h3 style={{textAlign: 'center'}}>&#8593; Release to refresh</h3>
                    }
                >
                    {
                       renderBody()
                    }
                </InfiniteScroll>
            }
            <InlineCallToActionComponent index={-1}/>
        </div>
    )
}

export default InferenceRequestListComponent;