import React from "react";
import { Link } from "react-router-dom";
export interface BreadcrumbComponentProps {
   /* crumbs: {
        name: string,
        path: string
    }[]*/
}

export interface BreadcrumbComponentState {

}
function BreadcrumbComponent (props: BreadcrumbComponentProps) {
    // Don't render a single breadcrumb.

    const url = document.location.pathname;
    const parts = url.split( '/');

    return (
        <div>
            {
                parts.map((part, index) =>{
                    let baseUrl = "";
                    for (let i = 1; i < index; i++) {
                        baseUrl += '/' + parts[i];
                    }
                    let content = (
                        <Link key={index} to={baseUrl + '/' + part}>
                            {part} /
                        </Link>
                    );
                    if (index + 1 === parts.length) {
                        content = (
                            <span key={index}>
                                {part}
                            </span>
                        );
                    }

                    return content;
                })
            }
        </div>
    );
};
export default BreadcrumbComponent;