import {loadStripe} from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
    CardElement, CardNumberElement, CardExpiryElement, CardCvcElement,
} from '@stripe/react-stripe-js';
import React, {ChangeEvent, useEffect, useState} from "react";
import {LoadingButtonComponentState} from "@/components/LoadingButtonComponent";
import {StripeElementsOptionsMode} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {GQLService} from "../services/GQLService";
import {CreatePaymentMethodCardData} from "@stripe/stripe-js/types/stripe-js/payment-intents";
import {Button} from "react-bootstrap";
import LoadingComponent from "../components/LoadingComponent";
import Accordion from "react-bootstrap/Accordion";
import {eventTrack} from "../services/util";


export interface PaymentComponentProps {
    onSuccess: any;
}

export interface PaymentComponentState {
    error?: any

    state: 'open' | 'locked'

    showCoupon?: boolean;
    coupon?:string;

    loaded?: boolean;

    basePrice?: number;
    appliedPrice?: number;
    couponObj?: { name: string };
}

function PaymentComponentInner(props: PaymentComponentProps) {
    const [state, setState] = useState<PaymentComponentState>({
        state: 'open',
        showCoupon: false
    });
    const stripe = useStripe();
    const elements = useElements();

    useEffect( () => {
        if (state.loaded){
            return;
        }
        GQLService.getStripeSubscriptionPrice({

        })
        .then((res) => {
            setState({
                ...state,
                basePrice: res.basePrice / 100,
                appliedPrice: res.appliedPrice / 100,
                loaded: true
            })
           res.appliedPrice
        });
    });
    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { name, value } = event.target;

        setState({
            ...state,
            [name]: value
        });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        eventTrack(
            'paymentSubmitStart'
        );
        setState({
            ...state,
            state: 'locked'
        });
        if (!stripe) {
            throw new Error("`stripe` is undefined")
        }
        if (elements == null) {
            return;
        }

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            // Show error to your customer
            setState({
                ...state,
                state: 'open',
                error: submitError
            });
            return;
        }

        // Create the PaymentIntent and obtain clientSecret from your server endpoint
        const {client_secret: clientSecret} = await GQLService.createPaymentIntent({});
     /*   const card = elements.getElement(CardElement);
        if (!card) {
            throw new Error("Cannot find CardElement");
        }*/
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (!cardNumberElement) {
            throw new Error("Cannot find CardElement");
        }
        const cartPaymentMethodOptions: CreatePaymentMethodCardData = {
            type: 'card',
            card: cardNumberElement,
        }
        const {error, paymentMethod} = await stripe.createPaymentMethod(cartPaymentMethodOptions);

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            eventTrack(
                'paymentSubmitError'
            );
            setState({
                ...state,
                state: 'open',
                error
            });
            return;
        }
        try {
            const response = await GQLService.quickSetupStripeSubscription({
                paymentMethodId: paymentMethod.id,
                coupon: state.coupon || null
            });
            props.onSuccess(response);
        }catch(err) {
            setState({
                ...state,
                state: 'open',
                error: err
            });
            return;
        }
        eventTrack(
            'paymentSubmitFinished'
        );

    };
    async function applyCoupon() {
        const res = await GQLService.getStripeSubscriptionPrice({
            coupon: state.coupon
        });

        setState({
            ...state,
            basePrice: res.basePrice / 100,
            appliedPrice: res.appliedPrice / 100,
            couponObj: res.coupon
        })
        eventTrack(
            'applyCoupon'
        );

    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="p-5">
                <form>
                    <div className="form-row">
                        <label htmlFor="exampleInputEmail1">Card Number</label>
                        <CardNumberElement className="form-control"/>
                    </div>
                    <div className="form-row">
                        <label htmlFor="exampleInputEmail1">Exp Date</label>
                        <CardExpiryElement  className="form-control" />
                    </div>
                    <div className="form-row">
                        <label htmlFor="exampleInputEmail1">CVC Number</label>
                        <CardCvcElement  className="form-control" />
                    </div>
                </form>
            </div>
            {/*<div className="p-5">
                <CardElement/>
            </div>*/}

            <div className="p-5">
                <h3>
                    {
                        state.basePrice && <>
                            {
                                state.basePrice === state.appliedPrice ?
                                    <>
                                        Price: ${state.basePrice}
                                    </>
                                :
                                    <>
                                        <s>${state.basePrice}</s> &nbsp; &nbsp; ${state.appliedPrice} {state.couponObj?.name}
                                    </>

                            }
                        </>
                    }

                </h3>
            </div>
            <div className="p-5">
                <a href="#" onClick={() =>  setState({ ...state, showCoupon: !state.showCoupon})}>Have A Coupon?</a>
                <Accordion id={'paymentComponent_coupon'} defaultActiveKey='1' activeKey={state.showCoupon ? '0' : '1'} >
                    <Accordion.Item eventKey="0">
                        <Accordion.Body>
                            <div className="col-12 mb-3">
                                <label className="form-label">Coupon</label>
                                <input
                                    type="text"
                                    name="coupon"
                                    className="form-control"
                                    value={state.coupon}
                                    onChange={handleChange}
                                />
                                <div className='btn btn-sm btn-info m-2' onClick={() => applyCoupon() }>Apply</div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="d-grid">
                <button className='btn btn-primary' type="submit" disabled={!stripe || !elements || state.state !== 'open'}>
                    Pay
                </button>
            </div>

            {/* Show error message to your customers */}
            {state.error && <div>{state.error.message}</div>}
        </form>
    );
};

function PaymentComponent(props: PaymentComponentProps) {
    if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
        throw new Error("Missing `process.env.REACT_APP_STRIPE_PUBLIC_KEY`");
    }

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const options: StripeElementsOptionsMode = {
        mode: 'subscription',
        amount: 1999, // This is $19.99
        currency: 'usd',
        // Fully customizable with appearance API.
        appearance: {
            /*...*/
        },
    };
    return (
        <Elements stripe={stripePromise} options={options}>
            <PaymentComponentInner onSuccess={props.onSuccess}/>
        </Elements>
    );

}

export default PaymentComponent;