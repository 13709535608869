import { Component } from "react";
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
export interface ImageEditComponentProps{
  imageSrc: string;
  onSaveImage:any,
  onModify: any,
}
export interface ImageEditComponentState {

}
export default class ImageEdit extends Component<ImageEditComponentProps, ImageEditComponentState> {
  state: ImageEditComponentState = {

  };
  render() {
    const { imageSrc, onSaveImage, onModify } = this.props;

    const options = {
      buttonStyles: {
        save: 'btn btn-outline-primary',
      },
    };
    return <FilerobotImageEditor      
      source={this.props.imageSrc}
      onSave={(editedImageObject, designState) => {
        console.log("ON SAVE HIT")
        this.props.onSaveImage(editedImageObject, designState);
      } }
      onBeforeSave={(imageFileInfo)=> {
        /*console.log("ON BEFORE SAVE HIT")
        this.props.onSaveImage(imageFileInfo);*/
        return false;
      }}
      /*onModify={(editedImageObject) => {
        console.log("ON Modify HIT")
        this.props.onModify(editedImageObject)
      }}*/
      // onClose={closeImgEditor}
      annotationsCommon={{
        fill: '#ff0000',
      }}
      Rotate={{ angle: 90, componentType: 'buttons' }}
      tabsIds={[TABS.ADJUST]} // or {['Adjust', 'Annotate', 'Watermark']}
      defaultTabId={TABS.ADJUST} // or 'Annotate'
      defaultToolId={TOOLS.CROP} // or 'Text'
      previewPixelRatio={1}
      savingPixelRatio={1}

    />
  }
}