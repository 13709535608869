import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {GQLService} from "../services/GQLService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import {useNavigate, useParams} from "react-router";
import InferenceRequestDetailComponent from "../components/InferenceRequestDetailComponent";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import ReactPaginate from 'react-paginate';
import LoadingComponent from "../components/LoadingComponent";
import InferenceRequestListComponent from "../components/InferenceRequestListComponent";

export interface InferenceRequestPageProps {

}

export interface InferenceRequestPageState {
    pageNum: number;
    inferenceRequests: any[],
    loaded?: boolean
}

const InferenceRequestListPage = (props: InferenceRequestPageProps) => {
    const [state, setState] = useState<InferenceRequestPageState>({
        pageNum: 0,
        inferenceRequests: []
    });
    const params = useParams();
    const navigate = useNavigate();




    return (
        <div className="container-fluid main-container step-6 light-grey-bg">
            <div className="container py-5">
                <div className="row">
                    <div className="col-12 text-center py-5">
                        <BreadcrumbComponent/>
                    </div>
                </div>


                <InferenceRequestListComponent query={{ username: params.username }} />
            </div>
        </div>
    );
};
export default InferenceRequestListPage;
