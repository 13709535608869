import { useState } from "react";
import galleryImages from "./Images";

const Gallery = () => {
    const [showMore, setShowMore] = useState(false);
    const images = galleryImages;
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const renderImages = () => {
        const imagesToShow = showMore ? images : images.slice(0, 12);

        return imagesToShow.map((image, index) => (
            <div className="col-6 col-sm-3 col-lg-2 mb-4" key={index}>
                <div className="thumbnail text-center">
                    <img src={image.url} alt={`Image ${index + 1}`} className="pet-img w-100 h-100" />
                    <div className="category pt-2">{image.category}</div>
                </div>
            </div>
        ));
    };

    return (
        <div className="container">
            <div className="row">{renderImages()}</div>
            {images.length > 12 && (
                <div className="col text-center">
                    <button onClick={toggleShowMore} className="btn btn-outline-secondary">
                        {showMore ? "Show less" : "Show more"}
                    </button>
                </div>
            )}
        </div>
    );
};

export default Gallery;