import {loadStripe} from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
    CardElement, CardNumberElement, CardExpiryElement, CardCvcElement,
} from '@stripe/react-stripe-js';
import React, {ChangeEvent, useEffect, useState} from "react";
import {LoadingButtonComponentState} from "@/components/LoadingButtonComponent";
import {StripeElementsOptionsMode} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {GQLService} from "../services/GQLService";
import {CreatePaymentMethodCardData} from "@stripe/stripe-js/types/stripe-js/payment-intents";
import {Button} from "react-bootstrap";
import LoadingComponent from "../components/LoadingComponent";
import Accordion from "react-bootstrap/Accordion";
import {eventTrack} from "../services/util";
import PaymentComponent from "../components/PaymentComponent";
import {GlobalModalService} from "../components/GlobalModalComponent";


export interface AfterInferenceModelComponentProps {
   //  onSuccess: any;
}

export interface AfterInferenceModelComponentState {
    error?: any

    state: 'open' | 'locked'

    showCoupon?: boolean;
    coupon?:string;

    loaded?: boolean;

    basePrice?: number;
    appliedPrice?: number;
    couponObj?: { name: string };
}

function AfterInferenceModelComponent(props: AfterInferenceModelComponentProps) {
    const [state, setState] = useState<AfterInferenceModelComponentState>({
        state: 'open',
        showCoupon: false
    });


    useEffect( () => {
        if (state.loaded){
            return;
        }
        GQLService.getStripeSubscriptionPrice({

        })
        .then((res) => {
            setState({
                ...state,
                basePrice: res.basePrice / 100,
                appliedPrice: res.appliedPrice / 100,
                loaded: true
            })
           res.appliedPrice
        });
    });
    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { name, value } = event.target;

        setState({
            ...state,
            [name]: value
        });
    };






    return (

            <div className='row'>
                <div className="col-sm-12 col-md-4">
                    <img
                        src={process.env.PUBLIC_URL + '/assets/img/nicha-astro-1.png'}
                        className="mw-100"
                        alt="Collage img"
                    />
                </div>
                <div className="col-sm-12 col-md-8">
                    <h2 className='text-center'>
                        We're over the 🌙 moon...
                    </h2>
                    <p>
                        ...that you've journeyed this far with us! 🎨🌌

                    </p>
                    <p>
                        Love your out of this 🌎 world images 🥰?! Great, let's 🐕 moonwalk on over to the 💸
                        payment  page! 💫🐾
                    </p>

                    <div className="d-grid">
                        <button className="btn btn-primary text-center " onClick={() => {
                            GlobalModalService.displayPaymentPage({})
                        }}>
                           BUY Now!!
                        </button>
                    </div>
                </div>

            </div>


);

}

export default AfterInferenceModelComponent;