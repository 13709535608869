import React from 'react';
import queryString from 'query-string';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import InferenceRequestList from "./pages/InferenceRequestList";
import Layout from "./components/Layout";
import UserList from "./pages/UserList";
import Login from "./pages/Login";
import TrainingModelList from "./pages/TrainingModelList";
import TrainingModelDetail from "./pages/TrainingModelDetail";
import TrainingInstanceList from "./pages/TrainingInstanceList";
import TrainingInstanceDetail from "./pages/TrainingInstanceDetail";
import TrainingModelJobList from "./pages/TrainingModelJobList";
import Home from "./pages/Home";
import UserProfile from "./pages/UserProfile";
import TrainingModelJobDetail from "./pages/TrainingModelJobDetail";
import InferenceRequestEditPage from "./pages/InferenceRequestEditPage";
import ReactGA from 'react-ga4';
import SignupCodeList from "./pages/SignupCodeList";
import InferenceResultDetail from "./pages/InferenceResultDetail";
import AffiliatePage from "./pages/AffiliatePage";
import AdminPage from './pages/AdminPage';
import TestPage from "./pages/TestPage";
import AdminBulkInferenceRequestPage from "./pages/AdminBulkInferenceRequestPage";
function App() {
  const parsed = queryString.parse(location.search);
  if (
      parsed.fpr &&
      !parsed.utm_source
  ) {
    const newHref = window.location.pathname + location.search + '&utm_source=fpr_' + parsed.fpr
    window.location.href = newHref;

  }
  if (
      document.location.host === 'www.drawnby.ai' ||
      document.location.host === 'drawnby.ai'
  ) {

    ReactGA.initialize([

      {
        trackingId: 'G-X2EP5DSQQ3',
        gaOptions: {

        }
      },

    ]);
  }


  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="signupcodes" element={<SignupCodeList />} />
          <Route path="community" Component={() => {
            window.location.href = 'https://discord.gg/5Tvf4FmTbW';
            return null;
          }}/>
          {/*<Route path="affiliates" element={<AffiliatePage />}/>*/}
          <Route path="users" element={<UserList />} />
          <Route path="login" element={<Login />} />
          <Route path="admin" element={<AdminPage />} />
          <Route path="admin/bulk/inferencerequests" element={<AdminBulkInferenceRequestPage />} />
          <Route path="test" element={<TestPage />} />
          <Route path=":username/reqs" element={<InferenceRequestList />} />
          <Route path=":username/reqs/:inferenceRequest" element={<InferenceResultDetail />} />
          <Route path=":username/reqs/:inferenceRequest/edit" element={<InferenceRequestEditPage />} />
          <Route path=":username/reqs/:inferenceRequest/images/:inferenceResult" element={<InferenceResultDetail />} />

          <Route path=":username" element={<UserProfile />} />
          <Route path=":username/models" element={<TrainingModelList />} />
          <Route path=":username/models/:model" element={<TrainingModelDetail />} />
          <Route path=":username/models/:model/reqs" element={<InferenceRequestList />} />
          <Route path=":username/models/:model/reqs/:request" element={<InferenceRequestEditPage />} />
          <Route path=":username/models/:model/reqs/new" element={<InferenceRequestEditPage />} />
          <Route path=":username/models/:model/jobs" element={<TrainingModelJobList />} />
          <Route path=":username/models/:model/jobs/:job" element={<TrainingModelJobDetail />} />
          <Route path=":username/models/:model/jobs/new" element={<TrainingModelJobDetail />} />
          <Route path=":username/models/new" element={<TrainingModelDetail />} />
          <Route path=":username/instances" element={<TrainingInstanceList />} />
          <Route path=":username/instances/:instance" element={<TrainingInstanceDetail />} />
          <Route path=":username/instances/new" element={<TrainingInstanceDetail />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}
/*
function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}
*/

export default App;
