import {Oval} from "react-loader-spinner";
import React, {forwardRef, useImperativeHandle, useState} from "react";
export interface LoadingButtonComponentProp  {
    disabled?: boolean;
    onClick: any;
    className?: string;
    alt?: string
    key?: any;
    children: React.ReactNode;
}

export interface LoadingButtonComponentState {
    isLoading?:boolean;
}
export interface LoadingButtonComponentHandle{
    reset: () => void;
}
const LoadingButtonComponent = forwardRef<LoadingButtonComponentHandle, LoadingButtonComponentProp>((props: LoadingButtonComponentProp, ref) => {
    const [state, setState] = useState<LoadingButtonComponentState>({
        isLoading: false
    });
    useImperativeHandle(ref, () => ({
        reset() {
            setState({
                ...state,
                isLoading: false
            })
        }
    }));
    return (
        <button
            disabled={state.isLoading || props.disabled}
            className={props.className}
            onClick={(event) => {

                props.onClick(event);
                setState({
                    ...state,
                    isLoading: true
                });
            }}
        >
            {state.isLoading && (
                <div>
                <Oval
                    height={30}
                    width={30}
                    wrapperStyle={{}}
                    wrapperClass="custom-oval"
                    visible={true}
                    ariaLabel='oval-loading'
                    strokeWidth={6}
                    strokeWidthSecondary={6}

                />&nbsp;
                </div>
            )}
            {
                !state.isLoading &&
                props.children
            }
        </button>
    );
});
export default LoadingButtonComponent;
