import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Landing, {getLocalStorageState} from "./Landing";
import {GQLService} from "../services/GQLService";
import WaitingComponent from "../components/WaitingComponent";
import PlansComponent from "../components/PlansComponent";
import InferenceRequestDetailComponent from "../components/InferenceRequestDetailComponent";
import LoadingComponent from "../components/LoadingComponent";
import {GlobalModalService} from "../components/GlobalModalComponent";

export interface HomeComponentProps {
    // wizardComponent: HomeWizard;
}
export interface HomeComponentState {
    loaded?: boolean;
    shouldRenderLanding?: boolean;
    shouldRenderWaiting?: boolean;

    hasFlaggedTrainingInstances?: boolean;
    inferenceRequests?: any[];

    canSignup?: boolean;
   /* trainingModels?: {
        _id?: string,
        name?: string,
        namespace?: string,
        user?: {
            username: string
        },

        jobs?: any[]
        inferenceRequests?: any[]
    }[]*/
}

const ITEMS_PER_PAGE = 10;
const Home = (props: HomeComponentProps) => {
    const [state, setState] = useState<HomeComponentState>({

    });
    const navigate = useNavigate();

    useEffect( () => {
        if (state.loaded){
            return;
        }
        refreshState();
    });


    async function refreshState() {
        if (!GQLService.isLoggedIn()) {
            const res = await GQLService.getHomePageLoggedOutData({});
            setState({
                ...state,
                shouldRenderLanding: true,
                loaded: true,
                canSignup: res.userCanSignup || false
            });
            return;
        }

        let query: any = {
            username: GQLService.getDecodedJWTToken().username,
            pagination: {
                limit: ITEMS_PER_PAGE,
                skip: (0) * ITEMS_PER_PAGE,
                orderBy: "createdAt",
                direction: -1
            }
        };

        const res = await GQLService.getHomePageLoggedInData({
            input: query,
            listTrainingInstanceInput: {
                username: GQLService.getDecodedJWTToken().username
            }
        }); // .listInferenceRequest(query);

        const trainingInstances = res.listTrainingInstance;
        const hasFlaggedTrainingInstances = !!trainingInstances.find((ti: any) => {
            return ti.flag === "NeedsReview" || ti.flag === "Rejected";
        })
        const inferenceRequests = res.listInferenceRequest;
        const lState = getLocalStorageState();

        if (
            lState?.step ||
            inferenceRequests.length === 0
        ) {
            setState({
                ...state,
                shouldRenderLanding: true,
                loaded: true
            });
            return;
        }
        let hasSucceededInferenceRequests = false;

        for (let inferenceRequest of inferenceRequests) {
            if(inferenceRequest.results.length > 0) {
                hasSucceededInferenceRequests = true;
            }
        }
        if (
            hasSucceededInferenceRequests &&
            !GQLService.hasSubscription()
        ) {
           navigate(`/${GQLService.user().username}`);
           return;
        }

        setState({
            ...state,
            inferenceRequests,
            shouldRenderWaiting:  !hasSucceededInferenceRequests && !hasFlaggedTrainingInstances,
            hasFlaggedTrainingInstances,
            loaded: true
        });

    }

    return <div>
        {!state.loaded && <LoadingComponent /> }
        {state.shouldRenderLanding && <Landing canSignup={state.canSignup} /> }
        {state.shouldRenderWaiting && <WaitingComponent /> }
        {state.hasFlaggedTrainingInstances &&
            <div className="container-fluid main-container step-6 light-grey-bg">
                <div className="container py-5">
                    <p>
                        Our AI has detected some problems with your photos, they've been submitted for human review.  We'll send you an email if the human confirms a problem or when your AI art has been generated.
                    </p>
                    <p>
                        If you have any questions email hello@DrawnBy.AI or click the chat in the bottom right corner.
                    </p>
                    <p>
                        Thank your for your patience and understanding,

                    </p>
                    <p>
                        The DrawnBy.AI team
                    </p>
                </div>
            </div>
        }
        {
            !state.hasFlaggedTrainingInstances &&
            !state.shouldRenderWaiting &&
            !state.shouldRenderLanding &&
            <div className="container-fluid main-container step-6 light-grey-bg">
                <div className="container py-5">
                    {
                        state.inferenceRequests?.map((inferenceRequest) => {
                            return <InferenceRequestDetailComponent key={inferenceRequest._id} inferenceRequest={inferenceRequest} refreshState={() => refreshState()} />
                        })
                    }

                </div>
            </div>
        }
    </div>;
}
export default Home;
