import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import {useNavigate, useParams} from "react-router";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import LoadingComponent from "../components/LoadingComponent";
import InferenceRequestListComponent from "../components/InferenceRequestListComponent";
import {GQLService} from "../services/GQLService";
import InferenceRequestDetailComponent from "../components/InferenceRequestDetailComponent";

export interface InferenceResultDetailProps {

}

export interface InferenceResultDetailState {
    inferenceRequest?: any,
    inferenceResultId: string | null;
    loaded?: boolean
}
const ITEMS_PER_PAGE = 8;

const InferenceResultDetail = (props: InferenceResultDetailProps) => {
    const params = useParams();
    const [state, setState] = useState<InferenceResultDetailState>({
        inferenceResultId: params.inferenceResult || null
    });

    const navigate = useNavigate();
    async function fetchData(options?: any) {
        let query: any = {
            _id: params.inferenceRequest,
            pagination: {
                limit: ITEMS_PER_PAGE,
                // skip: (state.page) * ITEMS_PER_PAGE,
                orderBy: "createdAt",
                direction: -1
            }
        };
        /*if (params.username) {
            query.username = params.username;
        }*/
        const inferenceRequests = await GQLService.listInferenceRequest(query);


        setState({
            ...state,
            loaded: true,
            inferenceRequest: inferenceRequests[0] || null,
        })
    }
    useEffect( () => {
        if (state.loaded){
            return;
        }
        fetchData();
    });

    function onSelectInferenceResult(event: any) {
        setState({
            ...state,
            inferenceResultId: event.inferenceResult._id
        })
        const url = GQLService.getShareUrl({
            username: event.inferenceRequest.user.username,
            inferenceResultId: event.inferenceResult._id,
            inferenceRequestId: event.inferenceRequest._id
        })

        window.history.replaceState(null, "DrawnBy.ai", url)

    }
    return (
        <div className="container-fluid main-container step-6 light-grey-bg">
            <div className="container py-5">
                {/*<div className="row">
                    <div className="col-12 text-center py-5">
                        <BreadcrumbComponent/>
                    </div>
                </div>*/}
                { state.inferenceRequest &&
                    <InferenceRequestDetailComponent refreshState={fetchData}
                                                 inferenceRequest={state.inferenceRequest}
                                                 inferenceResultId={state.inferenceResultId || undefined}
                                                     onSelect={onSelectInferenceResult}
                    />
                }
               {/* <InferenceRequestListComponent query=} inferenceResultId={params.inferenceResult} />*/}
                {
                    state.inferenceRequest &&
                    <InferenceRequestListComponent query={{ username: params.username }} filter={(inferenceRequest => inferenceRequest._id !== state.inferenceRequest._id) } />
                }
            </div>
        </div>
    );
};
export default InferenceResultDetail;
