const signUp = `
mutation signUp($input: UserCreateInput!) {
  signUp(input: $input) {
    user {
      _id
    }
  }
}
`;
const finishSignUp = `
mutation finishSignUp($input: UserFinishSignupInput!) {
  finishSignUp(input: $input) {
    accessToken
    refreshToken
    user  {
      _id
      createdAt
      updatedAt
      email
      username
      stripeCustomerId
      stripeSubscription
    }
  }
}
`;
const login = `
mutation login($input: LoginInput!) {
  login(input: $input) {
    accessToken
    refreshToken 
    user {
      _id
      createdAt
      updatedAt
      email
      username
      stripeCustomerId
      stripeSubscription
    }
  }
}
`;
const refresh = `
mutation refresh($input: RefreshInput!) {
  refresh(input: $input) {
    accessToken
    refreshToken
    expiration
    issuedAt
    idToken
  }
}
`;
const quickSetupTrainingModel = `
mutation quickSetupTrainingModel($input: QuickSetupTrainingModelInput!) {
  quickSetupTrainingModel(input: $input) {
    uploadUrls
  }
}
`;
const createTrainingModelJob = `
mutation createTrainingModelJob($input: TrainingModelJobCreateInput!) {
  createTrainingModelJob(input: $input) {
    _id
    model {
      _id
      user {
        _id
        username
      }
    }
  }
}
`;
const listTrainingModelJob = `
query listTrainingModelJob($input: TrainingModelJobFilterInput) {
  listTrainingModelJob(input: $input) {
    _id
    settings
    results
    trainingModelId
    model {
      _id
      user {
        _id
        username
      }
    }
  }
}
`;

const listUser = `
query listUser ($input: UserFilterInput) {
  listUser (input: $input) {
    _id
    username
    stripeSubscription
    email
  }
}
`;
const listUserWithModels = `
query listUser ($input: UserFilterInput) {
  listUser (input: $input) {
    _id
    username
    stripeSubscription
    email
    models {
      _id
      namespace  
    }
  }
}
`;
const deleteUser = `
mutation deleteUser($deleteUserId: ID!) {
  deleteUser(id: $deleteUserId) {
    deletedCount
  }
}

`;
const listSignupCode = `
query listSignupCode {
  listSignupCode {
    _id
    createdAt
    updatedAt
    code
    notes
    user {
      _id
      username
    }
  }
}
`;
const listTrainingModel = `
query listTrainingModel($input: TrainingModelFilterInput) {
  listTrainingModel(input: $input) {
    _id
    name
    namespace
    user {
      _id
      username
    }
   
  }
}
`;
const listTrainingModelDetailed = `
query listTrainingModel($input: TrainingModelFilterInput) {
  listTrainingModel(input: $input) {
    _id
    name
    namespace
    user {
      _id
      username
    }
    jobs {
      _id
      awsBatchJobId
    }
     inferenceRequests {
      _id
      awsBatchJobId
      createdAt
      prompt
      user {
        _id
        username
      }
      results{
        _id
        url
      }
    }
    trainingModelInstances {
      _id
      instance {
        _id
        name
        namespace
        user {
          _id
          username
        }
        files {
          downloadUrl
        }
      }
    }
  }
}
`;
const listTrainingInstance = `
query listTrainingInstance($input: TrainingInstanceFilterInput) {
  listTrainingInstance(input: $input) {
    _id
    name
    namespace
    flag
    user {
      _id
      username
    }
    files {
      path
      downloadUrl
    }
    trainingModelInstances {
      _id
      model {
        _id
        name
        namespace
        user {
          _id
          username
        }
      }
    }
  }
}
`;
const listInferenceRequestQuery = `
 listInferenceRequest(input: $input) {
    _id
    trainingModelId
    trainingModelVersionId
    prompt
    userId
    awsBatchJobId
    submittedAt
    completedAt
    user {
      _id
      username
    }
    results(showArchived: false) {
      _id     
      s3Path
      ownerLikeState
      queuedForRegenerationDate
      regeneratedAtDate
      url
      url256
    }
  }
`;
const listInferenceRequest = `
query listInferenceRequest($input: InferenceRequestFilterInput) {
 ${listInferenceRequestQuery}
}
`;


const homePageLoggedOut = `
query homePageLoggedOut {
   userCanSignup
}
`;
const homePageLoggedIn = `
  query homePageLoggedIn($input: InferenceRequestFilterInput, $listTrainingInstanceInput: TrainingInstanceFilterInput) {
     ${listInferenceRequestQuery}
     listTrainingInstance(input: $listTrainingInstanceInput) {
        _id
        name
        namespace
        flag
      }
  }
`;
const createTrainingModel = `
mutation createTrainingModel($input: TrainingModelCreateInput!) {
  createTrainingModel(input: $input) {
    _id
    namespace
    name
    user {
      _id
      username
    }
  }
}
`;
const createTrainingModelInstance = `
mutation createTrainingModelInstance($input: TrainingModelInstanceCreateInput!) {
  createTrainingModelInstance(input: $input) {
    _id
    trainingModelId
    trainingInstanceId
  }
}
`;
const updateTrainingModel = `
mutation updateTrainingModel($input: TrainingModelUpdateInput!) {
 updateTrainingModel(input: $input) {
    _id
    namespace
    name
    user {
      _id
      username
    }
  }
}
`;
const createTrainingInstance = `
mutation createTrainingInstance($input: TrainingInstanceCreateInput!) {
  createTrainingInstance(input: $input) {
    _id
    namespace
    name
    user {
      _id
      username
    }
  }
}
`;
const updateTrainingInstance = `
mutation updateTrainingInstance($input: TrainingInstanceUpdateInput!) {
 updateTrainingInstance(input: $input) {
    _id
    namespace
    name
    flag
    user {
      _id
      username
    }
  }
}
`;
const resetTrainingInstance = `
mutation resetTrainingInstance($input: ResetTrainingInstanceInput!) {
 resetTrainingInstance(input: $input) {
    _id
    name
    namespace
    user{
      _id
      username
    }
  }
}
`;
const createInferenceRequest = `
mutation createInferenceRequest($input: InferenceRequestCreateInput!) {
  createInferenceRequest(input: $input) {
    _id
    trainingModelId
    trainingModelVersionId
    prompt
    userId
    awsBatchJobId
    submittedAt
    completedAt
    user {
      _id
      username
    }  
     model {
      _id
      name
      namespace
      userId
      user {
        _id
        username
      }     
    }
  }
}
`;
const deleteInferenceRequest = `
mutation deleteInferenceRequest($deleteInferenceRequestId: ID!) {
  deleteInferenceRequest(id: $deleteInferenceRequestId) {
    deletedCount
  }
}
`;
const deleteTrainingModelInstance = `
mutation deleteTrainingModelInstance($deleteTrainingModelInstanceId: ID!) {
  deleteTrainingModelInstance(id: $deleteTrainingModelInstanceId) {
    deletedCount
  }
}
`;
const deleteTrainingInstanceFile = `
mutation deleteTrainingInstanceFile($input: DeleteTrainingInstanceFileInput!) {
  deleteTrainingInstanceFile(input: $input) {
    deletedCount
  }
}
`;
const getTrainingInstanceUploadUrls = `
mutation getTrainingInstanceUploadUrls($input: GetTrainingInstanceFileUploadUrlInput!) {
  getTrainingInstanceUploadUrls(input: $input) {
     uploadUrls
  }
}
`;
const createSignupCode = `
mutation createSignupCode($input: SignupCodeCreateInput!) {
  createSignupCode(input: $input) {
    _id
    createdAt
    updatedAt
    notes
    code
    userId
  }
}`;
const preSignup = `
mutation preSignup($input: PreSignupInput!) {
  preSignup(input: $input)
}`;
const createPaymentIntent = `
mutation createPaymentIntent {
  createPaymentIntent {
    client_secret
  }
}`;
const createStripePaymentMethod = `
mutation createStripePaymentMethod($input: StripePaymentMethodCreateInput!) {
  createStripePaymentMethod(input: $input) {
    id
    type
    livemode
    customer
    object
    created
  }
}`;
const updateInferenceResult = `
mutation updateInferenceResult($input: InferenceResultUpdateInput!) {
  updateInferenceResult(input: $input) {
    _id
    createdAt
    updatedAt
    inferenceRequestId
    s3Path
    ownerLikeState
    queuedForRegenerationDate
    regeneratedAtDate
    url
    url256
  }
}
`;
const quickSetupStripeSubscription = `
mutation quickSetupStripeSubscription($input: StripePaymentMethodCreateInput!) {
  quickSetupStripeSubscription(input: $input) {
    user {
      _id
      createdAt
      updatedAt
      email
      username
      stripeCustomerId
      stripeSubscription
    }
    paymentMethod {
      id
      type
      livemode
      customer
      object
      created
    }
  }
}`;
const requeueInferenceRequest = `
mutation requeueInferenceRequest($trainingModelId: ID!) {
  requeueInferenceRequest(trainingModelId: $trainingModelId) {
    _id
    createdAt
    updatedAt
    trainingModelId
    trainingModelVersionId
    prompt
    userId
    awsBatchJobId
    submittedAt
    completedAt
  }
}`;

const getStripeSubscriptionPrice = `
query getStripeSubscriptionPrice($input: GetStripeSubscriptionPriceInput) {
  getStripeSubscriptionPrice(input: $input) {
    appliedPrice
    basePrice
    coupon {
      amount_off
      name
      percent_off
    }
  }
}`;
const getDownVotedInferenceResultsPartial = `
  getDownVotedInferenceResults(input: $input) {
    _id
    createdAt
    updatedAt
    inferenceRequestId
    s3Path
    ownerLikeState
    queuedForRegenerationDate
    regeneratedAtDate
    url
    url256
    request {
      _id
      createdAt
      updatedAt
      trainingModelId
      trainingModelVersionId
      prompt
      userId
      awsBatchJobId
      submittedAt
      completedAt  
       user {
          _id
          username
      }
      model {
        user {
          _id
          username
        }
        userId
        _id
        name
      }
    }
  }`;
const emailListSignup = `
mutation emailListSignup($input: EmailListSignupInput!) {
  emailListSignup(input: $input)
}
`;
const getDownVotedInferenceResults = `
query getDownVotedInferenceResults($input: InferenceResultFilterInput) {
  ${getDownVotedInferenceResultsPartial}
}`;
const getAdminPage = `
query getAdminPage($input: InferenceResultFilterInput, $listFlaggedTrainingInstanceInput: TrainingInstanceFilterInput) {
  ${getDownVotedInferenceResultsPartial}
  listFlaggedTrainingInstance(input: $listFlaggedTrainingInstanceInput) {
    _id
    createdAt
    updatedAt
    name
    namespace
    userId
    trainingClassId
    settings
    flag
    user {
      _id
      email
      username
    }
 
    files {
      downloadUrl
      path
    }
    
    trainingModelInstances {
      model {
        _id
        userId
      }
    }
  }
}`;
const batchCreateInferenceRequest = `
  mutation batchCreateInferenceRequest($input: InferenceRequestBatchCreateInput!) {
    batchCreateInferenceRequest(input: $input) {
      _id
      createdAt
      updatedAt
      trainingModelId
      key
      trainingModelVersionId
      prompt
      metaData
      userId
      awsBatchJobId
      submittedAt
      completedAt
    }
  }
`;

export {
  signUp,
  finishSignUp,
  login,
  listUser,
  deleteUser,
  quickSetupTrainingModel,
  createTrainingModelJob,
  listTrainingModelJob,
  listInferenceRequest,
  listTrainingModel,
  createTrainingModel,
  createTrainingModelInstance,
  deleteTrainingModelInstance,
  updateTrainingModel,
  listTrainingInstance,
  createTrainingInstance,
  updateTrainingInstance,
  listTrainingModelDetailed,
  deleteInferenceRequest,
  createInferenceRequest,
  deleteTrainingInstanceFile,
  getTrainingInstanceUploadUrls,
  refresh,
  listSignupCode,
  createSignupCode,
  preSignup,
  createPaymentIntent,
  createStripePaymentMethod,
  quickSetupStripeSubscription,
  homePageLoggedOut,
  updateInferenceResult,
  requeueInferenceRequest,
  getDownVotedInferenceResults,
  getStripeSubscriptionPrice,
  getAdminPage,
  homePageLoggedIn,
  resetTrainingInstance,
  emailListSignup,
  listUserWithModels,
  batchCreateInferenceRequest
};